import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedUserSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetUserQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewUser = ({ selectId }) => {

    const selectedUser = useSelector(selectedUserSelector);
    const [UserId, setUserId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingUser, error: errorUser } = useGetUserQuery(UserId);
    // const ViewUser = ({showId}) => {
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.user?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
        res_get?.data?.user?.image && setSelectedFile(res_get?.data?.user?.image)
        setUserId(selectedUser ? selectedUser?.id : selectId)
    }, [])
    const userArray =
        [
            { label: 'Role', data: res_get?.data?.user?.role },
            { label: 'Name', data: res_get?.data?.user?.name },
            { label: 'Email', data: res_get?.data?.user?.email },
            { label: 'Mobile', data: res_get?.data?.user?.name },
        ]

    return (
        <div>

            {errorUser ? <div>{JSON.stringify(errorUser)}</div>
                : isLoadingUser ? <div>Loading Single User.....</div>
                    : res_get?.data?.user ?
                        <DZGenericView obj={userArray} imagePath={Config.digitalOceanLinkUserSmallImg} image={res_get?.data?.user?.image} checkImage={true} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewUser

