import {
  useAddOwnerPaymentMethodsMutation,
  useGetCurrentRestaurantFeatureQuery,
  useGetOwnerPaymentLinkQuery,
  useGetOwnerPaymentMethodsQuery,
  useGetOwnerUpdateCardMethodQuery,
  useUpdateRestaurantFeatureMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import './CreaditCard.css'

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddUpdateOwnerCard = () => {
  const [open, setOpen] = React.useState(false);
  const [addOwnerCard, responseUpdate] = useAddOwnerPaymentMethodsMutation();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [error, setError] = useState([]);
  const [count, setCount] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const card_id = searchParams?.get("id");
  var {data: owner_card} = useGetOwnerUpdateCardMethodQuery(card_id);
  var {data: res_get} = useGetOwnerPaymentMethodsQuery();


  useEffect(() => {

    if (card_id) {
      navigate("/restaurant-owner-card");
    }
  }, [card_id]);
  const countZero = () => {
    setCount(true);
  };

  const saveCard = () => {
    setLoading(true);

    addOwnerCard()
      .unwrap()
      .then((payload) => {
        console.log(payload);
        const link = payload?.data?.url;
        console.log(payload?.data);
        console.log(link);
        if (link) {
          window.location.href = link;
        } else {
          console.error("URL not found in payload:", payload);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error occurred while fetching the link:", error);
        setLoading(false);
      });
  };

  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };


  return (
    <>
      <div>
        {showBackDrop && <SimpleBackdrop responseUpdate={responseUpdate} />}
        <Snackbar
          sx={{ mt: 8 }}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleToClose}
        >
          <Alert onClose={handleToClose}   severity="success" sx={{ width: "100%" }}
          >
            {responseUpdate?.data?.message}
          </Alert>
        </Snackbar>

        <JumboDemoCard
          title={"Add Owner Card"}
          wrapperSx={{
            p: 0,
            backgroundColor: "background.paper",
            "&:last-child": { pb: 0 },
          }}
        >
          <div
            style={{
              marginTop: 25,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 25,
              width: "100%",
              padding: "0 24px 0 24px",
              marginBottom: 60,
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <Box sx={{ flex: 1 }}>
                <Grid container spacing={3.75}></Grid>
              </Box>
            </Box>

            <div style={{ width: "100%", display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '24px' }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "300px",
                  height: "200px",
                  background: "#99999950",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "60px",
                    height: "60px",
                    borderRadius: "500px",
                    background: "#7352C7",
                  }}
                >
                {/* <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    onClick={() => {
                      saveCard();
                      setShowBackDrop(true);
                      countZero();
                    }}
                    loading={loading}
                  > */}
                  <AddIcon loading={loading} sx={{ fontSize: "32px", color: "#FFF" }}  
                    onClick={() => {
                      saveCard();
                      setShowBackDrop(true);
                      countZero();
                    }}
                   />
                  {/* </LoadingButton> */}
                 
                </div>
              </div>
           {res_get?.data?.card&&   <div class={`credit__card ${res_get?.data?.card?.card_type === "visa" ? "visa--back" : res_get?.data?.card?.card_type === "mastercard" && "mastercard--back"} active__payment`}>
                <i class="ri-delete-bin-line delete__icon"></i>
                <div class="card__front">
                  <span class="card__nubmer">**** **** **** {res_get?.data?.card?.card_number_last_4}</span>
                  <div class="detail__creadit">
                    <span class="card__name"></span>
                    <div class="expiry">
                      <span class="exp__month">valid thru</span>
                      <span class="exp__num">{res_get?.data?.card?.expiration_date}</span>
                    </div>
                  </div>
                </div>
                {/* <div class="card__back">
                  <span class="cvc">000</span>
                </div> */}
              </div>}
            </div>
            {/* 
            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(0.5, 3) }}
            >
              <ListItemIcon
                sx={{ minWidth: 36, color: "text.secondary" }}
              ></ListItemIcon>
              <ListItemText
                secondary={
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    onClick={() => {
                      saveCard();
                      setShowBackDrop(true);
                      countZero();
                    }}
                    loading={loading}
                  >
                    Update
                  </LoadingButton>
                }
              />
            </ListItem> */}
          </div>
        </JumboDemoCard>
      </div>
    </>
  );
};

export default AddUpdateOwnerCard;
