import { Button, TextField } from "@mui/material";
import {
  selectedPoscAdminSelector,
  setSelectedPoscAdmin,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddPoscAdminMutation,
  useGetPoscAdminQuery,
  useGetAllRestaurantsQuery,
  useUpdatePoscAdminMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
const AddUpdatePoscAdmin = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedPoscAdmin = useSelector(selectedPoscAdminSelector);
  const { fnShowSnackBar } = useSnackBarManager();
  const {
    data: res_get,
    isLoading: isLoadingPoscAdmin,
    error: errorPoscAdmin,
  } = useGetPoscAdminQuery(selectedPoscAdmin?.id);
  const {
    data: res_get_all,
    isLoading: isLoadingRestaurant,
    error: errorRestaurant,
  } = useGetAllRestaurantsQuery();
  const [updatePoscAdmin, responseUpdate] = useUpdatePoscAdminMutation();
  const [addPoscAdmin, responseAdd] = useAddPoscAdminMutation();
  const bluePrint = BLUE_PRINTS.poscAdmin;
  const [poscAdminState, setPoscAdminState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [open, setOpen] = useState(false);

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  const addUpdatePoscAdminData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.posc_admin?.id);
    formData.append("name", poscAdminState.name);
    formData.append("restaurant_id", poscAdminState.restaurant_id);
    formData.append("email", poscAdminState.email);
    formData.append("password", poscAdminState.password);
    setOpen(true);

    // return false;
    // formData.append(
    //   "prices",
    //   JSON.stringify([
    //     {
    //       size_id: 4,
    //       price: 35,
    //     },
    //     {
    //       size_id: 5,
    //       price: 45,
    //     },
    //     {
    //       size_id: 6,
    //       price: 55,
    //     },
    //   ])
    // );
    const res =
      action === "add"
        ? await addPoscAdmin(formData)
        : await updatePoscAdmin(formData);

    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "PoscAdmin added successfully");
    } else {
      fnShowSnackBar("Something went wrong", true);
    }
  };

  useEffect(() => {
    setPoscAdminState(
      selectedPoscAdmin ? res_get?.data?.posc_admin : bluePrint
    );
  }, [res_get]);

  return (
    <div>
      {/* {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />} */}
      {/* <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} /> */}
      <SnackAndBackDrop
        showBackDrop={showBackDrop}
        responseAdd={responseAdd}
        responseUpdate={responseUpdate}
        setOpen={setOpen}
        open={open}
      />

      {poscAdminState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
          }}
        >
          {/* <LanguageSwitcher /> */}

          <DZTextValidation
            label="Name"
            variant="outlined"
            value={poscAdminState?.name}
            onChange={(e) => {
              setPoscAdminState((x) => ({ ...x, name: e.target.value }));
            }}
            error={error?.name}
          />

          <DZTextValidation
            label="Email"
            variant="outlined"
            value={poscAdminState?.email}
            onChange={(e) => {
              setPoscAdminState((x) => ({ ...x, email: e.target.value }));
            }}
            error={error?.email}
            readOnly={selectedPoscAdmin}
          />

          <DZTextValidation
            label="Password"
            variant="outlined"
            value={poscAdminState?.password}
            onChange={(e) => {
              setPoscAdminState((x) => ({ ...x, password: e.target.value }));
            }}
            error={error?.password}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 10,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            {selectedPoscAdmin ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdatePoscAdminData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdatePoscAdminData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedPoscAdmin(null));
                }}
              >
                Add
              </Button>
            )}
            {/* dispatch(setSelectedPoscAdmin(null)); */}
          </div>
          {/* <div style={{ width: '10%' }}>{JSON.stringify(poscAdminState)}</div> */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
      {/* {!selectedPoscAdmin &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}
    </div>
  );
};

export default AddUpdatePoscAdmin;
