import React from "react";
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Typography, } from "@mui/material";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import Slide from "@mui/material/Slide";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useContactsApp from '../../../app/pages/apps/contacts/hooks/useContactsApp';
import ContactForm from '../../../app/pages/apps/contacts/components/ContactForm/ContactForm';


const Transition = React.forwardRef(function Transition({ children, ...restProps }, ref) {
    return (
        <Slide direction="up" ref={ref} {...restProps}>
            {children}
        </Slide>
    );
});

const JumboDemoCard = ({ title, subheader, children, demoCode, noWrapper, wrapperSx, sx }) => {
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState('');

    const { showDialog, hideDialog } = useJumboDialog();
    const { setContactsListRefresh } = useContactsApp();

    const handleContactAdd = React.useCallback(() => {
        hideDialog();
        setContactsListRefresh(true);
    }, [hideDialog, setContactsListRefresh]);
    const showAddContactDialog = React.useCallback(() => {
        showDialog({
            title: "Add new contact",
            content: <ContactForm onSave={handleContactAdd} />
        });
    }, [handleContactAdd, showDialog]);

    const handle = () => {
        { showAddContactDialog() }
    }

    React.useEffect(() => {
        fetch(demoCode)
            .then((response) => response.text())
            .then((textContent) => {
                setText(textContent);
            });
    }, [demoCode]);

    const handleClickOpen = React.useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <React.Fragment>
            <Card
                sx={sx}
            >
                <CardHeader
                    title={
                        title && (
                            <Typography variant={"h4"} mb={0}>{title}</Typography>
                        )
                    }
                    subheader={
                        subheader && (
                            <Typography sx={{ mt: 1, color: "text.secondary" }}>{subheader}</Typography>
                        )
                    }
                    action={
                        <JumboIconButton
                            onClick={showAddContactDialog}
                            sx={{
                                margin: '-10px -6px -10px 0',
                                bgColor: 'blue'
                            }}
                        >
                                     {/* commentedCode here button .... */}

                        </JumboIconButton>
                    }
                />
                {
                    noWrapper ? children :
                        <CardContent
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: theme => theme.palette.action.hover,
                                ...wrapperSx,
                            }}
                        >
                            {children}
                        </CardContent>
                }
            </Card>
            {/* commentedCode here.... */}
        </React.Fragment >
    )
};

JumboDemoCard.propTypes = {
    title: PropTypes.node,
    subheader: PropTypes.node,
    children: PropTypes.node,
    demoCodeFile: PropTypes.string,
};


export default JumboDemoCard;