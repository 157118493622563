import { Button, TextField } from "@mui/material";
import {
  selectedRestaurantTableSelector,
  setSelectedRestaurantTable,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddRestaurantTableMutation,
  useGetRestaurantTableQuery,
  useUpdateRestaurantTableMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import QRCode from "react-qr-code";
import { Config } from "constant";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const validationRules = {
  table_number: {
    required: true,
    isNumber: true,
  },
  // table_code: {
  //   required: true,
  //   isNumber: true,
  // },
};

const AddUpdateRestaurantTable = ({ onClose }) => {
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const selectedRestaurantTable = useSelector(selectedRestaurantTableSelector);
  const { data: res_get } = useGetRestaurantTableQuery(
    selectedRestaurantTable?.id
  );

  const [updateRestaurantTable, responseUpdate] =
    useUpdateRestaurantTableMutation();
  const [addRestaurantTable, responseAdd] = useAddRestaurantTableMutation();
  const bluePrint = BLUE_PRINTS.restaurantTable;
  const [restaurantTableState, setRestaurantTableState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [open, setOpen] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const addUpdateRestaurantTableData = async (action) => {
    const isValid = validateForm();

    if (isValid) {
      const formData = new FormData();
      formData.append("id", res_get?.data?.restaurant_table?.id);
      formData.append("table_number", restaurantTableState.table_number);
      setOpen(true);

      if (!res_get?.data?.restaurant_table?.id) {
        
        formData.append("table_code", generateRandomNumber());
      }
      // formData.append("table_code", restaurantTableState.table_code);

      const res = action === "add"
        ? await addRestaurantTable(formData)
        : await updateRestaurantTable(formData);

      if (res?.data?.message) {
        fnShowSnackBar(
          res?.data?.message || "Restaurant table added successfully"
        );
      } else {
        fnShowSnackBar("Something went wrong", true);
      }
    }
  };
  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setRestaurantTableState(
      selectedRestaurantTable ? res_get?.data?.restaurant_table : bluePrint
    );
  }, [res_get]);

  const validateForm = () => {
    const errors = {};

    Object.keys(validationRules).forEach((field) => {
      const rules = validationRules[field];

      if (rules.required && !restaurantTableState[field]) {
        errors[field] = "This field is required";
      }

      if (rules.isNumber && isNaN(restaurantTableState[field])) {
        errors[field] = "Please enter a valid number";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const generateRandomNumber = () => {
    
    return Math.floor(10000 + Math.random() * 90000); // Generate a random 5-digit number
  };
  return (
    <div>
      {/* {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )} */}
      {/* <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      /> */}
                  <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />


      {restaurantTableState ? (
        <div style={addUpdateDivStyleGlobal}>
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 64,
              width: "100%",
            }}
          >
           { res_get?.data?.restaurant_table?.id&& <QRCode
              size={600}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={Config.tableOrderLink+'?table_number='+res_get?.data?.restaurant_table?.table_number+"&table_id="+res_get?.data?.restaurant_table?.id}
              viewBox={`0 0 300 300`}
            />}
          </div>
          <Grid item xs={12} md={6} lg={6}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <TextField
                fullWidth
                label="Table Number"
                type="number"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 1 }}
                variant="outlined"
                noOfRows={1}
                error={!!error.table_number}
                helperText={error.table_number}
                // error={!!formErrors.table_number}
                // helperText={formErrors.table_number}
                value={restaurantTableState?.table_number}
                onChange={(e) => {
                  setRestaurantTableState((x) => ({
                    ...x,
                    table_number: e.target.value,
                  }));
                }}
              />
            </Div>
          </Grid>
         { res_get?.data?.restaurant_table?.id&& <Grid item xs={12} md={6} lg={6}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <TextField
                fullWidth
                label="Table Code"
                type="number"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 1 }}
                variant="outlined"
                noOfRows={1}
                value={restaurantTableState?.table_code}
                readOnly  
              />
            </Div>
          </Grid>}
          {/* <TextField
                fullWidth
                label="Table Code"
                type="number"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 1 }}
                variant="outlined"
                noOfRows={1}
                value={restaurantTableState?.table_code}
                error={!!formErrors.table_code}
                helperText={formErrors.table_code}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setRestaurantTableState((x) => ({
                    ...x,
                    table_code: isNaN(newValue)
                      ? generateRandomNumber().toString()
                      : newValue,
                  }));
                }}
              /> */}

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedRestaurantTable(null));
              }}
            >
              Cancel
            </Button>
            {selectedRestaurantTable?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateRestaurantTableData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateRestaurantTableData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedRestaurantTable(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateRestaurantTable;
