import React from 'react';
import { Grid } from "@mui/material";

import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from 'app/layouts/layouts';
import OrderChart from './../charts/OrderChart';
import SaleChart from './../charts/SaleChart';
import CustomerChart from './../charts/CustomerChart';
import { selectedUserLoginSelector } from 'app/rkt_query/SelectedStuffReducer';
import { useSelector } from 'react-redux';

const Dashboard = () => {

    const { setActiveLayout } = useJumboApp();
    const selectedUserLogin = useSelector(selectedUserLoginSelector);
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
        if (selectedUserLogin) {
            window.location.reload(false);
        }
        
    }, []);

    return (
        <>
            {/* <StoreTokenCheck /> */}

            <h1>Orders</h1><br></br>
            <Grid container spacing={3.75}>

                <OrderChart />

                {/* {commentedCode here....} */}

            </Grid><br></br>

            <h1>Sales</h1><br></br>
            <Grid container spacing={3.75}>
                <SaleChart />
            </Grid><br></br>

            <h1>New Customers</h1><br></br>
            <Grid container spacing={3.75}>
                <CustomerChart />
            </Grid>

        </>
    );
};

export default Dashboard;
