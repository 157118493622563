import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import { Config } from 'constant';


const Logo = ({mini, mode, sx}) => {
    return (
        <Div sx={{display: "inline-flex", ...sx}}>
            {/* <Link href={'/${Config.endPointPath}/dashboard'}> */}
            <Link href={`/dashboard`}>
                {/* {
                    !mini ?
                        <img src={ mode === "light" ? `${ASSET_IMAGES}/logo.png` : `${ASSET_IMAGES}/logo-white.png`} alt="DZFood Admin" />
                        :
                        <img src={mode === "light" ? `${ASSET_IMAGES}/logo-short.png` : `${ASSET_IMAGES}/logo-short-white.png`} alt="DZFood Admin" />
                } */}
                {/* <img src={ `${ASSET_IMAGES}/logotop.png` } width="144px" alt={`${Config.projectName} Admin`} /> */}
                {/* <img src={process.env.REACT_APP_LOGO_PATH} alt={process.env.REACT_APP_NAME} /> */}
                <img src={ `${ASSET_IMAGES}/${process.env.REACT_APP_LOGO_PATH}` } width="144px" alt={process.env.REACT_APP_NAME} />
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
