import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllPoscAdminsQuery, BLUE_PRINTS, useGetPoscAdminQuery, useDeletePoscAdminMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { TITLE, selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Add from '.';
import { setSelectedPoscAdmin } from 'app/rkt_query/SelectedStuffReducer';
import ViewPoscAdmin from './ViewPoscAdmin';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import PoscAdmin from './AddUpdatePoscAdmin';
// import StoreTokenCheck from './../storetoken/StoreTokenCheck';

const PoscAdminList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deletePoscAdmin, responseDelete] = useDeletePoscAdminMutation();
  const { data: res_get_all, isLoading: isLoadingPoscAdmins, error: errorPoscAdmins } = useGetAllPoscAdminsQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.poscAdmin
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedPoscAdmin(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedPoscAdmin(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedPoscAdmin(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedPoscAdmin(row)); }
  bluePrint.handleDelete = (id) => { deletePoscAdmin(id); }

  // const reloadPage = () => {
  //    window.location.reload()
  // }

  //if (responseDelete?.isSuccess) { reloadPage() }



  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={TITLE.POSCADMINS}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="Posc Admin" data={res_get_all?.data?.posc_admins} bluePrint={bluePrint} ViewModel={() => <ViewPoscAdmin />} ModalContent={() => <PoscAdmin onClose={hideDialog} />} />
        
        </div>
      </JumboDemoCard>
    </>
  )
}


export default PoscAdminList