import { selectedAdminSelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetAdminQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
import { Config } from 'constant';

const ViewAdmin = ({ selectId }) => {
    const selectedAdmin = useSelector(selectedAdminSelector);

    console.log(selectedAdmin)
    
    
    const { data: res_get, isLoading: isLoadingAdmin, error: errorAdmin } = useGetAdminQuery(selectedAdmin?.id);
    
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.admin?.image);
    useEffect(() => {
        res_get?.data?.admin?.image && setSelectedFile(res_get?.data?.admin?.image)
    }, [])

 

    const adminArray =
    [
        { label: 'Restaurant', data: res_get?.data?.admin?.restaurant?.name },
        { label: 'Name', data: res_get?.data?.admin?.name },
        { label: 'Email', data: res_get?.data?.admin?.email },
       


    ]
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {errorAdmin ? <div>{JSON.stringify(errorAdmin)}</div>
                : isLoadingAdmin ? <div>Loading Single Accessory.....</div>
                    : res_get?.data?.admin ?
                        <DZGenericView obj={adminArray} imagePath={Config.digitalOceanLinkAdminSmallImg} image={selectedFile}  checkImage={true} closeBtn={true} /> 
                        : <div>Bad Code</div>}
        {/* {commentedCode here....} */}

        </div>
    )
}

export default ViewAdmin