import React, { useState } from 'react';
import { Card, CardContent, IconButton, TextField, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { useNavigate } from "react-router-dom";
import PP2Service from './../../../services/index';
import { Config } from './../../../../constant/index';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackBarManager } from 'app/hooks/useSnackBarManager';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ForgotPassword = () => {
    const { setActiveLayout } = useJumboApp();
    const [email, setEmail] = useState();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(true);
    const [open, setOpen] = React.useState(false);
    const { fnShowSnackBar } = useSnackBarManager();
    const navigate = useNavigate();
    const handleToClose = (event, reason) => {
        if ("clickaway" == reason) return;
        setOpen(false);

    };
    const handler = async () => {

        setLoading(true);
        let response = await PP2Service.fetch("admin-forgot-password", "post", {
            email: email,

        });

        if (response.success) {
            // Saved email to local storage
            setResponse(response)
            localStorage.setItem(Config.email, email)
            navigate('/verify-forgot-password')
            setLoading(false);
            fnShowSnackBar(response?.data?.message|| "Done successfully");
            // console.log(response, "response")
        } else {
            setOpen(true)
            setLoading(false);
            setResponse(response)
            fnShowSnackBar(response?.error?.data?.message|| "Something went wrong", true);
        }

    }
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    }, []);


    return (
        <>
            <Snackbar autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleToClose}  >

                <Alert onClose={handleToClose} severity="error" sx={{ width: '100%' }}>
                    {response?.message}
                </Alert>
            </Snackbar>
            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme => theme.spacing(4),
            }}>
                <Div sx={{ mb: 3, display: 'inline-flex' }}>
                    <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                        <img src={`${ASSET_IMAGES}/logotop.png`} alt="Jumbo React"  width="228px"/>
                        {/* <img src={`${ASSET_IMAGES}/logo.png`} alt="Jumbo React"  width="228px"/> */}
                    </Link>
                </Div>
                <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
                    <Div sx={{ position: 'relative', height: '200px' }}>
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="200"
                            image={getAssetPath(`${ASSET_IMAGES}/logotop.png`)}
                            // image={getAssetPath(`${ASSET_IMAGES}/applogo.png`)}
                        />
                        <Div sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme => alpha(theme.palette.common.black, .5),
                            p: theme => theme.spacing(3),
                        }}>
                            <Typography
                                variant={"h2"}
                                sx={{
                                    color: 'common.white',
                                    fontSize: '1.5rem',
                                    mb: 0
                                }}>
                                Forgot password
                            </Typography>
                        </Div>
                    </Div>
                    <CardContent>
                        <Div sx={{ mb: 3, mt: 1 }}>
                            <TextField
                                // sx3mb={3}  these props don't work here
                                // sx3mt={1}
                                required
                                fullWidth
                                type="email"
                                id="email"
                                label="Email"
                                onChange={(txt) => setEmail(txt.target.value)}
                            // defaultValue="demo@example.com"
                            />
                        </Div>
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            onClick={handler}

                            loading={loading}
                        >Reset Password</LoadingButton>
                       
                  {/* {commentedCode here....} */}
                    </CardContent>
                </Card>
                  {/* {commentedCode here....} */}

            </Div>
        </>
    );
};

export default ForgotPassword;
