import { Button, TextField, Fab, Box } from "@mui/material";
import {
  selectedCategorySelector,
  setSelectedCategory,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddCategoryMutation,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Rating, Typography } from "@mui/material";
import { DzNoBackgroundTranslatableModule } from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import DZDefaultImg from "app/DZcomponents/DZDefaultImg";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateCategory = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectedCategorySelector);
  const {
    data: res_get,
    isLoading: isLoadingCategory,
    error: errorCategory,
  } = useGetCategoryQuery(selectedCategory?.id);
  const { fnShowSnackBar } = useSnackBarManager();
  const [updateCategory, responseUpdate] = useUpdateCategoryMutation();
  const [addCategory, responseAdd] = useAddCategoryMutation();
  const bluePrint = BLUE_PRINTS.category;

  {
    /* {commentedCode here....} */
  }
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();

  const [disable, setDisabled] = useState([true]);
  const [categoryState, setCategoryState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    res_get?.data?.category?.image
  );

  const addUpdateCategoryData = async (action) => {
    try {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("id", res_get?.data?.category?.id);
      formData.append("name", JSON.stringify(categoryState.name));
  
      if (action === "add") {
        await addCategory(formData)
          .unwrap()
          .then((res) => {
            console.log(res, "res");
            setOpen(true);
            fnShowSnackBar(res?.data?.message|| "Category added successfully");
          })
      } else {
        await updateCategory(formData)
          .unwrap()
          .then((res) => {
            console.log(res, "res");
            setOpen(true);
            fnShowSnackBar(res?.data?.message || "Category updated successfully");
          })
      }
    } catch (error) {
      fnShowSnackBar("Something went wrong", true);
      console.error("Unexpected error:", error);
    }
  };
  

  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  {
    /* {commentedCode here....} */
  }

  useEffect(() => {
    res_get?.data?.category?.image &&
      setSelectedFile(res_get?.data?.category?.image);
    setCategoryState(selectedCategory ? res_get?.data?.category : bluePrint);
    setDisabled(selectedCategory ? false : true);
    {
      /* {commentedCode here....} */
    }
  }, [res_get]);

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  {
    /* {commentedCode here....} */
  }
  return (
    <div>
      <SnackAndBackDrop
        showBackDrop={showBackDrop}
        responseAdd={responseAdd}
        responseUpdate={responseUpdate}
        setOpen={setOpen}
        open={open}
      />

      {categoryState ? (
        <div style={addUpdateDivStyleGlobal}>
          {/* image start */}
          <DZDefaultImg src={imagePreview} selectedFile={selectedFile} />
          {/* {imagePreview ? (
            <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
          ) : (

            <DZImageCircle image={selectedFile} link={Config.digitalOceanLinkCategorySmallImg} style={{
              height: 100, width: 100, borderRadius: 50, alignSelf: "center",
            }}
            />

          )} */}

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>
            </label>
          </Box>

          <MultiDZTextValidation
            error={error?.[0]}
            label="Name"
            variant="outlined"
            value={categoryState?.name}
            onChange={(e) => {
              setCategoryState((x) => ({
                ...x,
                name: { ...x.name, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Name"
          />
          {/* {commentedCode here....} */}
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedCategory(null));
              }}
            >
              Cancel
            </Button>
            {selectedCategory?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCategoryData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCategoryData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedCategory(null));
                }}
              >
                Add
              </Button>
            )}
            {/* dispatch(setSelectedCategory(null)); */}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
      {/* {commentedCode here....} */}
    </div>
  );
};

export default AddUpdateCategory;
