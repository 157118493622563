import React from 'react';
import { Dialog, DialogActions, DialogContent, Zoom } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import LanguageSwitcher from './../../../../dz-components/buttons/LanguageSwitcher';


const DialogBase = ({ onClickCancel, showLanguageSwitcher = true, content, actions, title, subheader, contentProps, onClose, TransitionComponent, size = "md", disableDefaultClose, ...restProps }) => {
    const { open, hideDialog } = useJumboDialog();

    console.log("checking type", typeof onClickCancel)

    const handleClose = (event, reason) => {
        onClose();
        if (reason && reason == "backdropClick")
            return;
        else { !disableDefaultClose && hideDialog(); }
        // if (!disableDefaultClose)
        //     hideDialog();
    };

    return (
        <Dialog open={open} {...restProps} onClose={handleClose} TransitionComponent={TransitionComponent} fullWidth={true}
            maxWidth={size}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px 30px 0px 5px', position: 'relative' }}>

                {
                    title &&
                    <CardHeader title={title} subheader={subheader} sx={{ pb: 0 }} />

                }
                {showLanguageSwitcher && <LanguageSwitcher />}
            </div>
            <DialogContent {...contentProps}>
                {content}


            </DialogContent>
            {
                actions &&
                <DialogActions>
                    {actions}
                </DialogActions>
            }
                        {/* commentedCode here.... */}
        </Dialog>
    );
};

DialogBase.defaultProps = {
    onClose: () => { },
    TransitionComponent: Zoom,
    disableDefaultClose: false,
};

export default DialogBase;