import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'
import {  useDeleteProductAccessoryMutation, useGetAllProductAccessoriesQuery, BLUE_PRINTS } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { reload } from 'firebase/auth';
import { setSelectedProductAccessory } from 'app/rkt_query/SelectedStuffReducer';
import AddProductAccessory from './AddProductAccessory';
import Paper from '@mui/material/Paper';
import { Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableBody } from '@mui/material';
import { DZText } from 'app/DZcomponents/DZText';
import TableContainer from '@mui/material/TableContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';
import Ordering from '@jumbo/dz-components/drag-drop-ordering/Ordering';



const ProductAccessoriesList = () => {

    const location = useLocation();
    const navigate = useNavigate();

    console.log("from course", location?.state?.relationalObject);

    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteProductAccessory, responseDelete] = useDeleteProductAccessoryMutation();
    const { data: res_get_all, isLoading: isLoadingProductAccessory, error: errorProductAccessory } = useGetAllProductAccessoriesQuery(location?.state?.relationalObject?.id);

    const [showMode, setShowMode] = useState(false);

    const handleHideDialog = () => {
        hideDialog();

    }
   
    const bluePrint = BLUE_PRINTS.productAccessory
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedProductAccessory(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedProductAccessory(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedProductAccessory(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedProductAccessory(row)); }
    bluePrint.handleDelete = (id) => { deleteProductAccessory(id); }
    const handleProductAccessoryAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);
    const size = "xl";
    const showAddDialog = React.useCallback(() => {
        showDialog({
            title: "Add Product Accessory",
            size,
            content: <AddProductAccessory selectedId={location?.state?.relationalObject?.id} onClose={() => handleHideDialog()} onSave={handleProductAccessoryAdd} />
        });
    }, [showDialog]);
                      {/* {commentedCode here....} */}



    return (
        <>
            {/* <StoreTokenCheck /> */}
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
            <TableContainer component={Paper} >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <h2 style={{ marginLeft: '12px', fontWeight: 'normal' }}>Product Accessory</h2>
                        <TableRow>
                            <TableCell align="left">Product</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {


                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell component="th" scope="row">
                                    <DZText>{location?.state?.relationalObject?.title}</DZText>
                                </TableCell>
                                                     {/* {commentedCode here....} */}

                            </TableRow>

                        }


                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {<SimpleButtons title={"Product Accessory Ordering"} onClick={() =>
       showDialog({
          title: 'Product Accessory List',
          size,
          content: <Ordering dataList={res_get_all?.data?.product_accessories} table={"product_accessory"} />
        })
      } />}
            <JumboDemoCard
                title="Product Accessory"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        btnTitleAdd="Product Accessory"
                        data={res_get_all?.data?.product_accessories}
                        bluePrint={bluePrint}
                        ModalContent={() => <AddProductAccessory onClose={hideDialog} selectedId={ location?.state?.relationalObject?.id} />}
                        showAddButton={true}
                        hideViewBtn={false}
                    />

                </div>
            </JumboDemoCard>
        </>
    )
}

export default ProductAccessoriesList