import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import React, { useEffect, useState } from "react";
import { useAddHeroImageMutation, useGetAllRestaurantImageQuery, } from "app/rkt_query/storeApis";
import { Box, Fab, ListItem, ListItemText } from "@mui/material";
import { Config } from "constant";
import { DZImageRec } from "@jumbo/dz-components/imageCircle/DZImage";
import { LoadingButton } from "@mui/lab";

const MediaRoom = () => {

  const { data: res_get, isLoading: isLoadingRestaurantImage } = useGetAllRestaurantImageQuery();
  console.log('dataaaaaaaaa', res_get);
  const [updateImage] = useAddHeroImageMutation();

  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(res_get?.data?.restaurant_image?.image);


  useEffect(() => {
    const newSelectedFile = res_get?.data?.restaurant_image?.hero_image;
    if (newSelectedFile) {
      setSelectedFile(newSelectedFile);
    }
  }, [res_get]);


  const addUpdateRestaurantImg = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("image", selectedFile);

    updateImage(formData)
      .unwrap()
      .then((payload) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error updating restaurant image:", error);
        setLoading(false);
      });
  };

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    };
  };

  return (
    <div>
      <JumboDemoCard
        title={"Media Room"}
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
      >
        <div
          style={{
            width: '100%',
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          {imagePreview ? (
            <img
              src={imagePreview}
              alt=""
              style={{
                height: 150,
                width: 150,
                borderRadius: 10,
                alignSelf: "center",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 1.1)",
              }}
            />
          ) : (
            <DZImageRec
              image={selectedFile}
              link={Config.digitalOceanLinkRestaurantSmallImage}
              style={{
                height: 150,
                width: 150,
                alignSelf: "center",
                borderRadius: 10,
                alignSelf: "center",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 1.1)",
              }}
            />
          )}

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none", }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
                style={{ padding: 15 }}
              >
                Pick Hero Image
              </Fab>
            </label>
          </Box>

          <div>
            <ListItem style={{ textAlign: 'end', marginTop: 10, }} sx={{ p: (theme) => theme.spacing(0.5, 3) }} >
              <ListItemText
                secondary={
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 2 }}
                    onClick={() => {
                      addUpdateRestaurantImg();
                    }}
                    loading={loading}
                  >
                    Update
                  </LoadingButton>
                }
              />
            </ListItem>
          </div>
        </div>
      </JumboDemoCard>

    </div>
  );
};

export default MediaRoom;
