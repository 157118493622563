import { selectedRestaurantSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetRestaurantQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';


const ViewRestaurant = () => {
    const labels = ['exercise', 'video', 'order']
    const selectedRestaurant = useSelector(selectedRestaurantSelector);
    const { data: res_get, isLoading: isLoadingRestaurant, error: errorRestaurant } = useGetRestaurantQuery(selectedRestaurant?.id);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const bluePrint = BLUE_PRINTS.restaurant;
    const { hideDialog } = useJumboDialog();
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.restaurant?.image);

    useEffect(() => {
        res_get?.data?.restaurant?.image && setSelectedFile(res_get?.data?.restaurant?.image)
    }, [])

    const restaurantArray =
        [
        { label: 'Name', data: res_get?.data?.restaurant?.title },
        { label: 'email', data: res_get?.data?.restaurant?.email },
        { label: 'Phone', data: res_get?.data?.restaurant?.phone },
        { label: 'Pickup Time', data: res_get?.data?.restaurant?.pickup_time },
        { label: 'Delivery Time', data: res_get?.data?.restaurant?.delivery_time },
        { label: 'Delivery Min Price', data: res_get?.data?.restaurant?.delivery_min_price },
        { label: 'Discount', data: res_get?.data?.restaurant?.web_discount },
        { label: 'Discount', data: res_get?.data?.restaurant?.app_discount },
        { label: 'Delivery Distance', data: res_get?.data?.restaurant?.delivery_distance },
        { label: 'Stars', data: res_get?.data?.restaurant?.stars },
        { label: 'Address', data: res_get?.data?.restaurant?.address},
        { label: 'Description', data: res_get?.data?.restaurant?.detail[selectedLanguage] },
    ]

        


    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorRestaurant ? <div>{JSON.stringify(errorRestaurant)}</div>
                    : isLoadingRestaurant ? <div>Loading Single Restaurant.....</div>
                        : res_get?.data?.restaurant ?
                            <>
                                <DZGenericView obj={restaurantArray} imagePath={Config.AWSRestaurantOriginalImg} image={res_get?.data?.restaurant?.image} checkImage={true} closeBtn={true} />


                            </>

                            : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewRestaurant