import { Button, TextField, Fab, Box } from '@mui/material';
import { selectedCategorySelector, setSelectedCategory,  } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector,  } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddCategoryMutation, useGetCategoryQuery, useUpdateCategoryMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import DZDefaultImg from 'app/DZcomponents/DZDefaultImg';
import SnackAndBackDrop from 'app/DZcomponents/SnackAndBackDrop';
import { useSnackBarManager } from 'app/hooks/useSnackBarManager';




const AddUpdateCategory = ({ onClose }) => {

  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectedCategorySelector);
  const { data: res_get, isLoading: isLoadingCategory, error: errorCategory } = useGetCategoryQuery(selectedCategory?.id);

  const [updateCategory, responseUpdate,] = useUpdateCategoryMutation();
  const [addCategory, responseAdd,] = useAddCategoryMutation();
  const bluePrint = BLUE_PRINTS.category;



  const [disable, setDisabled] = useState([true]);

  const [categoryState, setCategoryState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [error, setError] = useState([]);
  const [count, setCount] = useState(true);
  const [open, setOpen] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(res_get?.data?.category?.image);

  const addUpdateCategoryData = async (action) => {
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('id', res_get?.data?.category?.id);
    formData.append('name', JSON.stringify(categoryState.name));
    const res = action === "add" ? await addCategory(formData) : await updateCategory(formData);
    if(res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done Successfully")
    } else{
      fnShowSnackBar(res?.error?.data?.message || "Something went wrong", true)
    }
    setOpen(true);
  }
  if (responseAdd?.error?.data?.errors && count) {
    setCount(false)
    setError(responseAdd?.error?.data?.errors)
  }

  if (responseUpdate?.error?.data?.errors && count) {
    setCount(false)
    setError(responseUpdate?.error?.data?.errors)
  }

  const countZero = () => {
    setCount(true)
  }
  console.log(responseUpdate?.error?.data?.errors)
  if (responseAdd?.error?.data?.errors && count) {
    setCount(false)
    setError(responseAdd?.error?.data?.errors)
  }

  if (responseUpdate?.error?.data?.errors && count) {
    setCount(false)
    setError(responseUpdate?.error?.data?.errors)
  }



  useEffect(() => {

    res_get?.data?.category?.image && setSelectedFile(res_get?.data?.category?.image)
    setCategoryState(selectedCategory ? res_get?.data?.category : bluePrint);
    setDisabled(selectedCategory ? false : true);

                  {/* {commentedCode here....} */}

  }, [res_get])

  console.log('update response', categoryState)
  const filehandle = (e) => {
    if (e.target.files.length !== 0) {

      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);

    }



  };
              {/* {commentedCode here....} */}

 

  return (
    <div>

      {/* {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />} */}
      {/* <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} /> */}
      <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />


      {categoryState ?
        <div style={addUpdateDivStyleGlobal}>

          {/* image start */}
          <DZDefaultImg src={imagePreview} selectedFile={selectedFile}/>
          {/* {imagePreview ? (
            <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
          ) : (

            <DZImageCircle image={selectedFile} link={Config.digitalOceanLinkCategorySmallImg} style={{
              height: 100, width: 100, borderRadius: 50, alignSelf: "center",
            }}
            /> */}

          {/* )} */}

          <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
            <label htmlFor="upload-photo">
              <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>

            </label>
          </Box>
                      {/* {commentedCode here....} */}


          <DZTextValidation error={error?.[0]} label="Name" variant="outlined" value={categoryState?.name[selectedLanguage]}
            onChange={(e) => { setCategoryState(x => ({...x,name:{ ...x.name,[selectedLanguage]: e.target.value }})) }}
            placeholder="Name"

          />
                   {/* {commentedCode here....} */}
       
          <div style={dialogButtonsStyle} >
            <Button variant="outlined" onClick={() => { onClose(); dispatch(setSelectedCategory(null)); }}>Cancel</Button>
            {
              selectedCategory?.id ? <Button variant="contained" onClick={() => { addUpdateCategoryData(); setShowBackDrop(true); countZero() }}>Update</Button>
                : <Button variant="contained" onClick={() => { addUpdateCategoryData("add"); setShowBackDrop(true); countZero(); dispatch(setSelectedCategory(null)); }}>Add</Button>
            }
            {/* dispatch(setSelectedCategory(null)); */}
          </div>
        </div>
        : <div>Loading....</div>
      }
                   {/* {commentedCode here....} */}


    </div>
  )
}

export default AddUpdateCategory