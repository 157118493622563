import React from 'react';
import {  Card, CardContent, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useNavigate } from 'react-router-dom';


export const handleGotoNavigate = (agentDetail, title, data, navigate) => {
    console.log("agent", data)
    var navigateTo;
    if (title === "Company") { navigateTo = "/companies" }
    else if (title === "Course") { navigateTo = "/courses" }
    else if (title === "Module") { navigateTo = "/modules" }
    else if (title === "Topic") { navigateTo = "/modules" }
    if (title === "Topic") {
        navigate(navigateTo, {
            state: {
                obj: '',
                relationalObjectTopic: data,
                relationalObject: data?.module?.course,
            }
        })
    }
    else {
        navigate(navigateTo, {
            state: {
                obj: '',
                relationalObject: data,
            }
        })
    }
}

// navigate("/companies", {
//     state: {
//       relationalObject: data,
//     },
//   });

const DZCard = ({ agentDetail, title, onClickCallback, data }) => {
    const navigate = useNavigate();
    return (
        <Card sx={{ overflow: 'visible', mt: 4, }}>
            <CardContent>
                <Stack direction={"row"} spacing={0.25} alignItems={'center'}>
                <Typography  ><b>{title}</b></Typography>
                
                    {/* <ApartmentOutlinedIcon /> */}
                    <div style={{ width: "80%" }}>
                        <h3 style={{ wordWrap: "break-word" }} ><b>{agentDetail}</b></h3>
                    </div>
                   
              
                    {/* <VisibilityOutlinedIcon sx={boxSX} onClick={() => { onClickCallback(); }} /> */}
                    {/* <Typography variant={"h5"}>{agentDetail}</Typography> */}
                </Stack>
            </CardContent>
        </Card>
    );
};
/* Todo agent detail prop */
export default DZCard;


            {/* commentedCode here.... */}