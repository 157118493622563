import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";

const SnackAndBackDrop = ({ showBackDrop, responseAdd, responseUpdate,setOpen, open }) => {
    console.log('snackbar',open);

    return (
        <>

            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
          { open &&  <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} setOpen1={setOpen} />}

        </>
    )
}


export default SnackAndBackDrop