import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField, Fab, Box } from '@mui/material';
import { selectedUserSelector, setSelectedUser, selectedCompanySelector, setSelectedCompany } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddUserMutation, useGetUserQuery, useUpdateUserMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown, { DZSimpleDropDown } from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField, { DZDateField } from '../../../@jumbo/dz-components/textboxes/DZTextField';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import { DzNoBackgroundTranslatable, DzNoBackgroundTranslatableModule } from '@jumbo/dz-components/labels/DZLabel';
import DZDefaultImg from 'app/DZcomponents/DZDefaultImg';
import SnackAndBackDrop from 'app/DZcomponents/SnackAndBackDrop';
import { useSnackBarManager } from 'app/hooks/useSnackBarManager';



const AddUpdateUser = ({ onClose }) => {

    const dispatch = useDispatch();
    const selectedUser = useSelector(selectedUserSelector);
    const { data: res_get, isLoading: isLoadingUser, error: errorUser } = useGetUserQuery(selectedUser?.id);

    const [updateUser, responseUpdate,] = useUpdateUserMutation();
    const [addUser, responseAdd,] = useAddUserMutation();
    const bluePrint = BLUE_PRINTS.user;
    const { fnShowSnackBar } = useSnackBarManager();


    const [disable, setDisabled] = useState([true]);

    const [userState, setUserState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [error, setError] = useState([]);
    const [count, setCount] = useState(true);
    const [open, setOpen] = useState(false);


    const [imagePreview, setImagePreview] = useState("");
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.user?.image);
    const roles = ['user', 'deliver']
    const addUpdateUserData = async (action) => {
        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('id', res_get?.data?.user?.id);
        formData.append('name', userState.name);
        formData.append('email', userState.email);
        formData.append('password', userState.password);
        formData.append('mobile', userState.mobile);
        formData.append('role', userState.role);
        setOpen(true);

       const res = action === "add" ? await addUser(formData) : await updateUser(formData);
       if (res?.data?.message) {
        fnShowSnackBar(
          res?.data?.message || "Done successfully",
        );
      } else {
        fnShowSnackBar("Something went wrong", true);
      }
    }
    if (responseAdd?.error?.data?.errors && count) {
        setCount(false)
        setError(responseAdd?.error?.data?.errors)
    }



    const countZero = () => {
        setCount(true)
    }
    console.log(responseUpdate?.error?.data?.errors)
    if (responseAdd?.error?.data?.errors && count) {
        setCount(false)
        setError(responseAdd?.error?.data?.errors)
    }




    useEffect(() => {

        res_get?.data?.user?.image && setSelectedFile(res_get?.data?.user?.image)


        // setCompanyId(res_get1?.data?.company?.id)
        setUserState(selectedUser ? res_get?.data?.user : bluePrint);
        // setInputFields(modules)
        // setUserState(...courseState?.company_id && )
        setDisabled(selectedUser ? false : true);

        // if (res_get1?.data?.company?.id) {
        //   //dispatch(setSelectedUser(null));
        //   console.log("Checking")
        // }

    }, [res_get])

    console.log('update response', userState)
    // const companies = res_get_all?.data?.companies?.map((company) => (company))
    // const callCompanyTeams = () => {
    //   setCompanyId(courseState?.company_id)
    // }
    const filehandle = (e) => {
        if (e.target.files.length !== 0) {

            setSelectedFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);

        }



    };

    {/* {commentedCode here....} */ }


    return (
        <div>

            {/* {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />} */}
            {/* <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} /> */}

            <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />

            {userState ?
                <div style={addUpdateDivStyleGlobal}>

                    {/* image start */}
                    <DZDefaultImg src={imagePreview} selectedFile={selectedFile} />
                    {/* {imagePreview ? (
                        <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
                    ) : (

                        <DZImageCircle image={selectedFile} link={Config.AWSUserOriginalImg} style={{
                            height: 100, width: 100, borderRadius: 50, alignSelf: "center",
                        }}
                        />

                    )} */}

                    <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
                        <label htmlFor="upload-photo">
                            <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
                            />
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                            >
                                Upload photo
                            </Fab>

                        </label>
                    </Box>
                    {/* <DZDropdown error={error?.company_id} placeholder={'Select Company'} value={courseState?.company_id} data={companies}
            onChange={(e) => { setUserState(x => ({ ...x, company_id: e.target.value, })); callCompanyTeams(); }} /> */}

                    <DZSimpleDropDown value={userState?.role} data={roles} name="role"
                        placeholder="Select Role" onChange={(e) => { setUserState(x => ({ ...x, role: e.target.value, })) }} />

                    <DZTextValidation error={error?.name} label="Name" variant="outlined" value={userState?.name}
                        onChange={(e) => { setUserState(x => ({ ...x, name: e.target.value })) }}
                        placeholder="Name"

                    />

                    <DZTextValidation error={error?.email} label="Email" variant="outlined" value={userState?.email}
                        onChange={(e) => { setUserState(x => ({ ...x, email: e.target.value })) }}
                        placeholder="Email"

                    />
                    <DZTextValidation error={error?.mobile} label="Mobile" variant="outlined" value={userState?.mobile}
                        onChange={(e) => { setUserState(x => ({ ...x, mobile: e.target.value })) }}
                        placeholder="Mobile"

                    />
                    <TextField label="Password" type="password" variant="outlined" value={userState?.password}
                        {...(error?.password && { error: true, helperText: error?.password })}
                        onChange={(e) => { setUserState(x => ({ ...x, password: e.target.value })) }}
                        placeholder="Password"

                    />

                    {/* {commentedCode here....} */}

                    <div style={dialogButtonsStyle} >
                        <Button variant="outlined" onClick={() => { onClose(); dispatch(setSelectedUser(null)); }}>Cancel</Button>
                        {
                            selectedUser?.id ? <Button variant="contained" onClick={() => { addUpdateUserData(); setShowBackDrop(true); countZero() }}>Update</Button>
                                : <Button variant="contained" onClick={() => { addUpdateUserData("add"); setShowBackDrop(true); countZero(); dispatch(setSelectedUser(null)); }}>Add</Button>
                        }
                        {/* dispatch(setSelectedUser(null)); */}
                    </div>
                    {/* <div style={{ width: '10%' }}>{JSON.stringify(courseState)}</div> */}
                </div>
                : <div>Loading....</div>
            }
            {/* {commentedCode here....} */}
        </div>
    )
}

export default AddUpdateUser