import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { Component, useEffect, useState } from 'react'
import { useGetAllRestaurantsQuery, BLUE_PRINTS, useGetRestaurantQuery, useDeleteRestaurantMutation, useGetAllSuperAdminRestaurantsQuery, useRestaurantLoginForSuperAdminMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { setSelectedRestaurant, setSelectedUserLogin } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { Height } from '@mui/icons-material';
import { useJumboApp } from '@jumbo/hooks';
import { decryptStoredData, encryptStoredData } from '../storetoken/StoreTokenCheck';
import { Config } from 'constant';
import { LAYOUT_NAMES } from 'app/layouts/layouts';
// import ViewRestaurant from './ViewRestaurant';
// import AddUpdateRestaurant from './AddUpdateRestaurant.js';


const RestaurantListForSuperAdmin = () => {
  const dispatch = useDispatch();
  const [userLogin, userLoginResponse] = useRestaurantLoginForSuperAdminMutation();
  const { setActiveLayout } = useJumboApp();
  const navigate = useNavigate();
  const { data: res_get_all, isLoading: isLoadingRestaurants, error: errorRestaurants } = useGetAllSuperAdminRestaurantsQuery();

  React.useEffect(() => { setActiveLayout(LAYOUT_NAMES.SOLO_PAGE); }, []);

    const location = useLocation();
  
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteRestaurant, responseDelete] = useDeleteRestaurantMutation();
    // const { data: res_get_all, isLoading: isLoadingRestaurants, error: errorRestaurants } = useGetAllRestaurantsQuery();
    const [showMode, setShowMode] = useState(false);
    const selectedLanguage = useSelector(selectedLanguageSelector);
  

  const handler = (restaurant) => {
    const formData = new FormData();
    formData.append("restaurant_id", restaurant?.id);
    
    userLogin(formData).unwrap().then((payload) => {
      dispatch( setSelectedUserLogin("admin") );
      encryptStoredData(Config.setAdminRestaurant, payload.data.restaurants?.[0]?.id);
      encryptStoredData(Config.adminApiTokenName, payload.data.access_token);
      encryptStoredData( Config.roleName, "admin" );
      navigate("/dashboard");
    })
    .catch((error) => { });
  };

  const bluePrint = BLUE_PRINTS.restaurantListForSuperAdmin;
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedRestaurant(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedRestaurant(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedRestaurant(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedRestaurant(row)); }
    bluePrint.handleDelete = (id) => { deleteRestaurant(id); }
  bluePrint.getCustomCols = (row) => {return ([
    { field: "owner-name", sortable: false, flex: 1, align: 'center', renderCell: (params) => ( <label>{params?.row?.admins?.[0]?.email}</label> ) },
    { field: "login-action", headerName: "", sortable: false, flex: 1, align: 'center', headerAlign: 'center',
      renderCell: (params) => (<Button variant="contained" color="primary" onClick={() => handler(params.row)}>Login</Button> )
    },
  ])}

  return (
    <JumboDemoCard wrapperSx={{ p: 0, backgroundColor: "background.paper", "&:last-child": { pb: 0 }, }} sx={{ minHeight: '100vh' }} >
      <div style={{ height: "100%", width: "100%" }}>
        <DataTable hideHeader hideActions data={res_get_all?.data?.restaurants} bluePrint={bluePrint} showAddButton={true} />
      </div>
    </JumboDemoCard>
  );
}


export default RestaurantListForSuperAdmin