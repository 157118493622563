import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";

const Chart = ({ data, domain, title, dataKey = {} }) => {

    return (
        <>

            <JumboDemoCard title={title} wrapperSx={{ pt: 0, backgroundColor: 'background.paper' }}>

                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                        <XAxis dataKey="name" />
                        <YAxis type="number" domain={domain} tickCount={6} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={<CustomTooltip dataKey={dataKey} />} labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} cursor={false} />
                        <Legend />
                        <defs>
                            <linearGradient x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
                                <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
                            </linearGradient>
                        </defs>
                        <defs>
                            <linearGradient x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
                                <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
                            </linearGradient>
                        </defs>
                        {
                            dataKey && Object.keys(dataKey).length > 1 ? (
                                Object.entries(dataKey).map(([key, value], index) => (
                                    key != 'total' && (
                                        <Bar
                                            key={index}
                                            dataKey={key}
                                            fill={value}
                                            animationBegin={index * 1500}
                                            animationDuration={1500}
                                        />
                                    )
                                ))
                            ) : dataKey && Object.entries(dataKey).map(([key, value], index) => (
                                <Bar
                                    key={index}
                                    dataKey={key}
                                    fill={value}
                                    animationBegin={0}
                                    animationDuration={1500}
                                />
                            ))
                        }

                        {/* <Bar dataKey="pending" fill="#ffc658" animationBegin={0} animationDuration={1500} />
                        <Bar dataKey="complete" fill="#82ca9d" animationBegin={1500} animationDuration={1500} />
                        <Bar dataKey="cancelled" fill="#c9170e" animationBegin={3000} animationDuration={1500} /> */}
                        {/* <Bar dataKey="total" fill="#c9170e" animationBegin={0} animationDuration={1500} /> */}

                    </BarChart>
                </ResponsiveContainer>
            </JumboDemoCard>

        </>
    )
};

export default Chart;

const CustomTooltip = ({ active, payload, dataKey }) => {
    if (active && payload) {
        return (
            <div style={{ backgroundColor: '#fff', border: '1px solid #999', padding: '10px' }}>
                <p>{` ${payload[0]?.payload?.name}`}</p>

                {Object.entries(payload[0]?.payload).map(([key, value], index) => (
                    key !== 'name' && (
                        <p key={index}>{`${key}: ${value}`}</p>
                    )
                ))}

                {/* <p>{`Pending: ${payload[0]?.payload?.pending}`}</p>
                <p>{`Complete: ${payload[0]?.payload?.complete}`}</p>
                <p>{`Cancelled: ${payload[0]?.payload?.cancelled}`}</p>
                <p>{`Total: ${payload[0]?.payload?.total}`}</p> */}
            </div>
        );
    }

    return null;
};

