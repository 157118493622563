import { selectedContactSelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetContactQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewContact = ({ selectId }) => {
    const selectedContact = useSelector(selectedContactSelector);

    console.log(selectedContact)
    
    
    const { data: res_get, isLoading: isLoadingContact, error: errorContact } = useGetContactQuery(selectedContact?.id);
    
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.contact?.image);
    useEffect(() => {
        res_get?.data?.contact?.image && setSelectedFile(res_get?.data?.contact?.image)
    }, [])

 

    const contactArray =
    [
        { label: 'Name', data: res_get?.data?.contact?.name },
        { label: 'Email', data: res_get?.data?.contact?.email },
        { label: 'Subject', data: res_get?.data?.contact?.subject },
        { label: 'Message', data: res_get?.data?.contact?.message },
       


    ]
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {errorContact ? <div>{JSON.stringify(errorContact)}</div>
                : isLoadingContact ? <div>Loading Single Accessory.....</div>
                    : res_get?.data?.contact ?
                        <DZGenericView obj={contactArray}  closeBtn={true} /> 
                        : <div>Bad Code</div>}
                        {/* {commentedCode here....} */}

        </div>
    )
}

export default ViewContact