import { Button } from '@mui/material';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';

const commonStyles = {

  border: 1,
  width: '4rem',
  height: '4rem',
  maxWidth: '40px',
  maxHeight: '40px',
  minWidth: '40px',
  minHeight: '40px',
};

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [enColor, setEnColor] = useState("primary")
  const [dkColor, setDkColor] = useState("")

  const colorEn = () => {
    setEnColor("primary")
    setDkColor("")

  }

  const colorDk = () => {
    setEnColor("")
    setDkColor("primary")
  }

  return (
   
    <div style={{ display: 'flex', gap: 5 }}>
            {/* commentedCode here.... */}
      <Button sx={{ ...commonStyles, borderRadius: '50%' }} size="small" variant={selectedLanguage === LANGUAGES?.ENGLISH ? 'contained' : 'outlined'} onClick={() => dispatch(setSelectedLanguage(LANGUAGES?.ENGLISH))}>en</Button>
      <Button sx={{ ...commonStyles, borderRadius: '50%' }} size="small" variant={selectedLanguage === LANGUAGES?.DENMARK ? 'contained' : 'outlined'} onClick={() => dispatch(setSelectedLanguage(LANGUAGES?.DENMARK))}>dk</Button>
                 {/* commentedCode here.... */}
     </div>
  )
}

export default LanguageSwitcher




