import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'
import { useGetAllAdminsQuery, BLUE_PRINTS, useDeleteAdminMutation } from 'app/rkt_query/storeApis';
import { useDispatch,  } from 'react-redux';
import { TITLE,  } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { setSelectedAdmin } from 'app/rkt_query/SelectedStuffReducer';
import ViewAdmin from './ViewAdmin';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import Admin from './AddUpdateAdmin';

const AdminList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteAdmin, responseDelete] = useDeleteAdminMutation();
  const { data: res_get_all, isLoading: isLoadingAdmins, error: errorAdmins } = useGetAllAdminsQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.admin
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedAdmin(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedAdmin(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedAdmin(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedAdmin(row)); }
  bluePrint.handleDelete = (id) => { deleteAdmin(id); }

  // const reloadPage = () => {
  //    window.location.reload()
  // }

  //if (responseDelete?.isSuccess) { reloadPage() }



  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={TITLE.ADMINS}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="Admin" data={res_get_all?.data?.admins} bluePrint={bluePrint} ViewModel={() => <ViewAdmin />} ModalContent={() => <Admin onClose={hideDialog} />} />
        
        </div>
      </JumboDemoCard>
    </>
  )
}


export default AdminList