import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedAccessorySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetAccessoryQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewAccessory = ({ selectId }) => {

    const selectedAccessory = useSelector(selectedAccessorySelector);
    const [AccessoryId, setAccessoryId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingAccessory, error: errorAccessory } = useGetAccessoryQuery(AccessoryId);
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.accessory?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
        res_get?.data?.accessory?.image && setSelectedFile(res_get?.data?.accessory?.image)
        setAccessoryId(selectedAccessory ? selectedAccessory?.id : selectId)
    }, [])
    const accessoryArray =
        [
            { label: 'Title', data: res_get?.data?.accessory?.title[selectedLanguage] },

        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorAccessory ? <div>{JSON.stringify(errorAccessory)}</div>
                : isLoadingAccessory ? <div>Loading Single Accessory.....</div>
                    : res_get?.data?.accessory ?
                        <DZGenericView obj={accessoryArray} checkImage={false} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewAccessory

