import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllProductsQuery, BLUE_PRINTS, useGetProductQuery, useDeleteProductMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { setSelectedProduct } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { boxSX } from '@jumbo/dz-components/data-table/GlobalFunctions';
import ViewProduct from './ViewProduct';
import AddUpdateProduct from './AddUpdateProduct.js';
import { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';
import Ordering from '@jumbo/dz-components/drag-drop-ordering/Ordering';


const ProductList = () => {

  const dispatch = useDispatch();

  
  const location = useLocation();

  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteProduct, responseDelete] = useDeleteProductMutation();



  const { data: res_get_all, isLoading: isLoadingProducts, error: errorProducts } = useGetAllProductsQuery();
  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);


  const bluePrint = BLUE_PRINTS.product
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedProduct(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedProduct(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedProduct(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedProduct(row)); }
  bluePrint.handleDelete = (id) => { deleteProduct(id); }


  const navigate = useNavigate();

  const handleBack = () => {

    navigate("/categories", {
      // state: {
      //   relationalObject: res_get?.data?.learning_journey?.topic?.module?.course,
      //   learningJourneyId: location?.state?.relationalObject,
      //   module: res_get?.data?.module,
      // },
    });
  }

  const size = "xl";

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      <Snackbar responseAdd={responseDelete} />

      {<SimpleButtons title={"Product Ordering"} onClick={() =>
       showDialog({
          title: 'Product List',
          size,
          content: <Ordering dataList={res_get_all?.data?.products} table={"product"} />
        })
      } />}
      <JumboDemoCard
        title="Products"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable

            btnTitleAdd="Product"
            data={res_get_all?.data?.products}
            bluePrint={bluePrint}
            ViewModel={() => <ViewProduct />}
            ModalContent={() => <AddUpdateProduct onClose={hideDialog} fullscreen={true} />}
            showAddButton={true}
            viewTitle="Add Product Accessory"
            showHideViewTitle={false}
          />
          </div>
      </JumboDemoCard>
    </>
  );
}


export default ProductList