import React, { useState } from "react";
import { useAddGalleryMutation } from "app/rkt_query/storeApis";
import { useSelector } from "react-redux";
import { selectedHotelSelector } from "app/rkt_query/SelectedStuffReducer";
import "./IconHover.css";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import MultiImageUpload from "app/DZcomponents/MultiImageUpload/MultiImageUpload";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
const AddUpdateGallery = ({ onClose }) => {
  const selectedHotel = useSelector(selectedHotelSelector);
  const [files, setFiles] = React.useState([]);
  const [addHotelSportImage, responseAdd] = useAddGalleryMutation();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [open, setOpen] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const uploadImages = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
      setOpen(true);
    });
    await addHotelSportImage(formData)
      .then((res) => {
        console.log(res, "res");
        if(res?.data?.message){
          // fnShowSnackBar(res?.data?.message || "Done Successfully");
          onClose();
        } else{
          fnShowSnackBar(res?.error?.data?.message || "Something went wrong", true);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        fnShowSnackBar(err?.error?.data?.message || "Something went wrong", true);
      })
      .finally(() => {
      });
    setFiles([]);
  };
  return (
    <>
      <SnackAndBackDrop
        showBackDrop={showBackDrop}
        responseAdd={responseAdd}
        setOpen={setOpen}
        open={open}
      />

      {/* {responseAdd.isLoading === true && (
        <SimpleBackdrop responseAdd={responseAdd} />
      )}
      <Snackbar
        responseAdd={responseAdd?.isSuccess === true ? responseAdd : ""}
      /> */}
      <MultiImageUpload
        onClose={onClose}
        imgfiles={files}
        setimgfiles={setFiles}
        uploadImagesfiles={uploadImages}
      />
    </>
  );
};
export default AddUpdateGallery;
