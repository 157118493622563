import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import {useGetAllSizeGroupsQuery, BLUE_PRINTS, useGetSizeGroupQuery, useDeleteSizeGroupMutation,} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import SizeGroup from './AddUpdateSizeGroup';
import { setSelectedSizeGroup } from 'app/rkt_query/SelectedStuffReducer';
import ViewSizeGroup from './ViewSizeGroup';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { IconButton, Button, Typography } from '@mui/material';



const SizeGroupList = () => {

  const location = useLocation();
  const navigate = useNavigate();


  // console.log("In SizeGroup company State ", location?.state?.company);
  console.log("In SizeGroup Relational Objext", location?.state);

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteSizeGroup, responseDelete] = useDeleteSizeGroupMutation();
  const { data: res_get_all, isLoading: isLoadingSizeGroups, error: errorSizeGroups } = useGetAllSizeGroupsQuery();
  const [showMode, setShowMode] = useState(false);
  const bluePrint = BLUE_PRINTS.sizeGroup
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedSizeGroup(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedSizeGroup(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedSizeGroup(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedSizeGroup(row)); }
  bluePrint.handleDelete = (id) => { deleteSizeGroup(id); }




  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
     
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"SizeGroup"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="SizeGroup"
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.size_groups_sizes}
              bluePrint={bluePrint}
              ViewModel={() => <ViewSizeGroup />}
              ModalContent={() => <SizeGroup onClose={hideDialog} />}
        
              showAddButton={false}
           
            />
              </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default SizeGroupList