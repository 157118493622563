import React, { useState } from "react";
import "./MultiImageUpload.css";
import { useDispatch } from "react-redux";
import { setSelectedHotel } from "app/rkt_query/SelectedStuffReducer";

function MultiImageUpload({ onClose, show_image = true, uploadImagesfiles, imgfiles, setimgfiles }) {
  const dispatch = useDispatch();

  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
 


  const handleImageChange = (e) => {
    const files = e.target.files;
    const selectedImagesArray = Array.from(files);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...selectedImagesArray,
    ]);
    const imagePreviews = [];
    for (let i = 0; i < selectedImagesArray.length; i++) {
      const imageURL = URL.createObjectURL(selectedImagesArray[i]);
      imagePreviews.push(imageURL);
    }
    setPreviewImages((prevPreviewImages) => [
      ...prevPreviewImages,
      ...imagePreviews,
    ]); 
    console.log(imgfiles);
    setimgfiles(selectedImagesArray);
  };

  const removeImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);

    const updatedPreviews = [...previewImages];
    updatedPreviews.splice(index, 1);
    setPreviewImages(updatedPreviews);
  };

  return (
    <div>
      <div className="popup__page">
        <div className="pop__up">
          <div className="header">
            <span className="header__title">Upload Image</span>
          </div>
          <div style={{width:'100%'}} className="upload__container">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageChange}
            />

            
            <div className="image__container">
              {previewImages.map((imageURL, index) => (
                <div key={index} className="image__data">
                  <i
                    className="close__icon ri-close-line"
                    onClick={() => removeImage(index)}
                  ></i>
                  <div className="data__container">
                    <span className="data">
                      {(selectedImages[index].size / 1024).toFixed(2)} KB
                    </span>
                    <span className="data">
                      {selectedImages[index].name.length > 12
                        ? selectedImages[index].name.substring(0, 12) + "..."
                        : selectedImages[index].name}
                    </span>
                  </div>
                  <img src={imageURL} alt={`Preview ${index}`} />
                </div>
              ))}
              {previewImages[0] ? (
               <>
                {show_image ? 
                  <div>
                    <img src="addimg.png" alt="Upload PIC" />
                  </div>
              : null}
               </>
              ) : (
                <div className="upload__name">
                  <i className="ri-upload-cloud-2-fill"></i>
                  <span>Add Your Images</span>
                </div>
              )}
            </div>
          </div>
          <div className="btn__container" style={{width:'100%'}}>
            <button
              className="btn"
              onClick={() => {
                onClose(); // Add this line to close the popup
                dispatch(setSelectedHotel(null));
              }}
            >
              Cancel
            </button>

            <button className="btn" 
            onClick={uploadImagesfiles}
            >
              <i className="ri-upload-cloud-2-fill"></i> Upload Images
            </button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiImageUpload;

