import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Card, Grid } from '@mui/material';

import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetAllOrdersQuery } from "app/rkt_query/storeApis";
import DZCard from '@jumbo/dz-components/card/DZCard';
import OrderDetail from './Order';
import { Pusher } from 'pusher-js';
import pusher from 'pusher';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: theme.spacing(2),
  },

}));
const OrderList = () => {
  const { data: res_get_all, refetch } = useGetAllOrdersQuery();
  const [orders, setOrders] = useState(res_get_all?.data?.orders)
  const [activeTab, setActiveTab] = useState('pending');

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const ordersPerPage = 10;



  useEffect(() => {
    const channel = pusher.subscribe("new-order");
    channel.bind("order-status", (data) => {
      console.log(data);
      refetch();

    });
    return () => {
      pusher.unsubscribe("new-order");
    };
  }, []);


  useEffect(() => {
    setOrders(res_get_all?.data?.orders)
  }, [res_get_all])



  //for tabs changing index
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const newOrders = orders?.filter(order => order?.order_status === 'pending').sort((a, b) => b.id - a.id);
  const inProcessOrders = orders?.filter(order => order?.order_status === 'in-process').sort((a, b) => b.id - a.id);
  const readyOrders = orders?.filter(order => order?.order_status === 'ready').sort((a, b) => b.id - a.id);
  const completeOrders = orders?.filter(order => order?.order_status === 'completed').sort((a, b) => b.id - a.id);


  return (
    <>
      <Grid container spacing={3.75}>
        {
          <>

            <Grid item xs={12} sm={6} lg={3}>
              <DZCard
                title={"New Orders"}

                agentDetail={newOrders?.length}

              />

            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <DZCard
                title={"In Process Orders"}

                agentDetail={inProcessOrders?.length}

              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <DZCard
                title={"Ready Orders"}

                agentDetail={readyOrders?.length}

              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <DZCard
                title={"Completed Orders"}

                agentDetail={completeOrders?.length}

              />
            </Grid>

          </>
        }
      </Grid>

      {orders ? <JumboDemoCard
        title="Orders"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >
        <Div sx={{ width: 1000, maxWidth: '100%', bgcolor: 'background.paper', }}>

          <div className={classes.root}>
            <Tabs value={value} onChange={handleChange} variant="scrollable"
              scrollButtons="auto"  >
              <Tab label="New" />
              <Tab label="In Process" />
              <Tab label="Ready" />
              <Tab label="Complete" />
            </Tabs>
            {value === 0 && (
              <Typography component="div" className={classes.tabContent}>
                <h2>New Orders</h2>
                <OrderDetail data={newOrders} status={"In Process"} statusUpdate={"in-process"} />

              </Typography>
            )}
            {value === 1 && (
              <Typography component="div" className={classes.tabContent}>
                <h2>In Process Orders</h2>
                <OrderDetail data={inProcessOrders} status={"Ready"} statusUpdate={"ready"} />
              </Typography>
            )}
            {value === 2 && (
              <Typography component="div" classes={classes.tabContent}>
                <h2>Ready Orders</h2>
                <OrderDetail data={readyOrders} status={"Completed"} statusUpdate={"completed"} />
              </Typography>
            )}
            {value === 3 && (
              <Typography component="div" classes={classes.tabContent}>
                <h2>Completed Orders</h2>
                <OrderDetail data={completeOrders} />
              </Typography>
            )}
          </div>
        </Div>

      </JumboDemoCard>

        :
        //  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        //         <CircularProgress />
        //       </Box>
        <></>
      }
    </>

  )
}

export default OrderList

