import React from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import {  TableCell, TableRow } from "@mui/material";
import Typography from "@mui/material/Typography";
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useSelector } from 'react-redux';

const DragHandle = SortableHandle(() => <DragHandleIcon sx={{ cursor: 'grab', verticalAlign: 'middle' }} />);

const OrderingData = (props) => {
    console.log(props)
    const { data } = props;
    // const [starred, setStarred] = useState(data.starred);

    const selectedLanguage = useSelector(selectedLanguageSelector);

    // const { id, title,name, order,table } = data;

    return (

        <TableRow>
            <TableCell width={"3%"} sx={{ pl: 3 }}>
                <DragHandle />
            </TableCell>
            {/* { commentedCode here ....} */}

            <TableCell width={"10%"}>
                <Typography variant={"h6"} mb={0}>{data?.id}</Typography>
            </TableCell>

            {data?.title&& <TableCell width={"20%"}>
                <Typography variant={"h6"} mb={0}>{data?.title}</Typography>
            </TableCell>}
           {data?.name&& <TableCell width={"20%"}>
                <Typography variant={"h6"} mb={0}>{data?.name}</Typography>
            </TableCell>}
           {data?.accessory?.title&& <TableCell width={"20%"}>
                <Typography variant={"h6"} mb={0}>{data?.accessory?.title?.[selectedLanguage]}</Typography>
            </TableCell>}

            {/* { commentedCode here ....} */}

            <TableCell width={"15%"}>
                <Typography variant={"h6"} mb={0}>{data?.order}</Typography>
            </TableCell>

            {/* { commentedCode here ....} */}

        
        </TableRow>
    );
};

export default SortableElement(OrderingData);

const menuItems = [
    {
        title: "Edit",
        slug: "edit",
    },
    {
        title: "Delete",
        slug: "delete",
    }
]
