import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useState } from 'react'
import {
  useGetAllAccessoriesQuery, BLUE_PRINTS, useDeleteAccessoryMutation,
} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Accessory from './AddUpdateAccessory';
import { setSelectedAccessory } from 'app/rkt_query/SelectedStuffReducer';
import ViewAccessory from './ViewAccessory';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';



const AccessoryList = () => {

  const location = useLocation();
  const navigate = useNavigate();
  console.log("In Accessory Relational Objext", location?.state);

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteAccessory, responseDelete] = useDeleteAccessoryMutation();
  const { data: res_get_all, isLoading: isLoadingAccessorys, error: errorAccessorys } = useGetAllAccessoriesQuery();
  // const { data: res_get_all_companies, isLoading: isLoadingCompanies, error: errorCompanies } = useGetAllCompaniesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.accessory
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedAccessory(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedAccessory(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedAccessory(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedAccessory(row)); }
  bluePrint.handleDelete = (id) => { deleteAccessory(id); }



  const [accessories, setAccessories] = useState(res_get_all?.data?.accessories)
  {/* { commentedCode here ....} */ }


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"Accessory"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="Accessory"
              relationalObject={location?.state?.relationalObject}
              // listData={res_get_all_companies?.data?.companies}
              companyName={location?.state?.company}
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.accessories}
              bluePrint={bluePrint}
              ViewModel={() => <ViewAccessory />}
              ModalContent={() => <Accessory onClose={hideDialog} />}
              showAddButton={false}
                        //  { commentedCode here ....} 
            />
            {/* <DataTable btnTitleAdd="accessory" data={res_get_all?.data?.accessorys} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowAccessory/> : <Accessory onClose={hideDialog} />} /> */}
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default AccessoryList