import { selectedSizeGroupSelector } from 'app/rkt_query/SelectedStuffReducer';
import { BLUE_PRINTS, useGetSizeGroupQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewSizeGroup = ({ selectId }) => {

    const selectedSizeGroup = useSelector(selectedSizeGroupSelector);
    const [SizeGroupId, setSizeGroupId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingSizeGroup, error: errorSizeGroup } = useGetSizeGroupQuery(SizeGroupId);

    useEffect(() => {
    
        setSizeGroupId(selectedSizeGroup ? selectedSizeGroup?.id : selectId)
    }, [])
    const sizeGroupArray =
        [
            { label: 'Title', data: res_get?.data?.size_group?.title },
            { label: 'Short Title', data: res_get?.data?.size_group?.short_title },

        ]
 
    return (
        <div>

            {errorSizeGroup ? <div>{JSON.stringify(errorSizeGroup)}</div>
                : isLoadingSizeGroup ? <div>Loading Single SizeGroup.....</div>
                    : res_get?.data?.size_group ?
                        <DZGenericView obj={sizeGroupArray}  closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewSizeGroup

