import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Fab } from '@mui/material';
import { DropzoneDialog } from 'react-mui-dropzone';

const DZDropzone = ({state, setState, onSave}) => {
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);

    const handleClose = () => {
        setOpen(false);
        setState(false)
    };

    const handleSave = (files) => {
        setFiles(files);
        console.log('files', files);
        setOpen(false);
        setState(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    

    return (
        <div>
        
            {/* <Fab
                onClick={handleOpen}
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
            >
                Upload photo
            </Fab> */}

            <DropzoneDialog
                open={open}
                onSave={()=>{onSave(); setOpen(false)}}
                // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={handleClose}
            />

        </div>
    );
};

export default DZDropzone;
