import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'

import {  BLUE_PRINTS, useGetProductQuery, useDeleteProductMutation,  useGetAllOrdersQuery } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { setSelectedProduct } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import ViewOrderDetail from './ViewOrderDetail';
import UpdateOrderStatus from './UpdateOrderStatus.js';


const Orders = () => {
  const { data: res_get_all, isLoading: isLoadingOrder, error: errorOrder } = useGetAllOrdersQuery();
  const dispatch = useDispatch();
  //const params = useParams();

  const location = useLocation();

  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteProduct, responseDelete] = useDeleteProductMutation();
 
 
  const { data: res_get_learning_journey_products, isLoading: isLoadingLearningJourneyProducts, error: errorLearningJourneyProducts } = useGetProductQuery();

  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);


  const bluePrint = BLUE_PRINTS.order
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedProduct(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedProduct(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedProduct(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedProduct(row)); }
  bluePrint.handleDelete = (id) => { deleteProduct(id); }


  const navigate = useNavigate();

  const handleBack = () => {

    navigate("/categories", {
      // state: {
      //   relationalObject: res_get?.data?.learning_journey?.topic?.module?.course,
      //   learningJourneyId: location?.state?.relationalObject,
      //   module: res_get?.data?.module,
      // },
    });
  }

  // useEffect(() => {
  //     console.log(res_get_all_learning_journeys)
  // }, [res_get_all_learning_journeys])

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      <Snackbar responseAdd={responseDelete} />

      <Button variant='contained' sx={{ width: '15%' }} onClick={handleBack} >Back</Button>

      <JumboDemoCard
        title="Orders"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable
            btnTitleAdd="Orders"
            data={res_get_all?.data?.orders}
            bluePrint={bluePrint}
            ViewModel={() => <ViewOrderDetail />}
            ModalContent={() => <UpdateOrderStatus onClose={hideDialog} fullscreen={true} />}
            showAddButton={false}
            viewTitle2="Order Status"
          />
       
        </div>
      </JumboDemoCard>
    </>
  );
}


export default Orders