import React, { useEffect, useState } from "react";
import "./TableOrder.css";
import { useGetTableOrdersQuery } from "app/rkt_query/storeApis";
import { useSelector } from "react-redux";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";

const TableOrders = () => {
  const { data: res_get_all, refetch } = useGetTableOrdersQuery();
  const [activeTable, setActiveTable] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [detail, setDetail] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);

  const formattedDate = (timeDate) => {
    return new Date(timeDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  useEffect(() => {
    if (!orders?.length) {
      setDetail(null);
      console.log(orders, "sds");
    }
  }, [orders]);

  const received = (timeDate) => {
    const currentTimestamp = new Date();
    const orderTimestamp = new Date(timeDate);
    const timeDifferenceInMilliseconds = currentTimestamp - orderTimestamp;
    return Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
  };
  return (
    <div className="tableOrder__container">
      <div className="table__div">
        <h1 className="contaner__heading">Tables</h1>
        {res_get_all?.data?.table_orders?.map((table, index) => (
          <div
            key={index}
            className={`order__card ${
              activeTable === table ? "active__card" : ""
            }`}
            onClick={() => {
              setActiveTable(table);
              setOrders(table?.orders);
            }}
          >
            <span className="text__dark">Table #{table?.table_number}</span>
            <span className="text__light">
              No of orders {table?.orders_count}
            </span>
          </div>
        ))}
      </div>

      <div className="order__div table__div">
        <h1 className="contaner__heading">Table Orders</h1>

        {orders?.map((order, index) => (
          <div
            //  className={`order__card   ${index==0?"active__card":""}`}
            key={index}
            className={`order__card ${
              activeOrder === order ? "active__card" : ""
            }`}
            onClick={() => {
              setActiveOrder(order);
              setDetail(order);
            }}
          >
            <div className="card__content">
              <span className="text__dark">Order #{order?.id}</span>
              <span className="text__light">
                {formattedDate(order?.created_at)}
              </span>
            </div>
            <div className="detail__opener">
              <span className="text__normal">Kr.{order?.amount}</span>
              <svg
                className="forward__arrow"
                xmlns="http://www.w3.org/2000/svg"
                width={28}
                height={28}
                viewBox="0 0 28 28"
                fill="none"
              >
                <rect
                  width={28}
                  height={28}
                  rx={10}
                  fill="var(--primary-light)"
                />
                <path
                  d="M11 9L16.0781 13.2318C16.5579 13.6316 16.5579 14.3684 16.0781 14.7682L11 19"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        ))}
      </div>

      <div className="detail__section">
        <h1 className="contaner__heading">Order Details</h1>

        {detail && orders ? (
          <>
            <div className="detail__container order__detail">
              <div className="left__side">
                <span className="text__dark">Order #{detail?.id}</span>
                <span className="text__light">
                  {formattedDate(detail?.created_at)}
                </span>
              </div>
              <div className="right__side">
                <div className="personal__detail">
                  <span className="text__dark">{detail?.guest_user?.name}</span>
                </div>
                <img
                  className="square__img"
                  src="https://picsum.photos/200/200"
                  alt=""
                />
              </div>
            </div>
            <div className="border__line"></div>
            <div className="detail__container">
              <span className="text__light">
                Order Received:
                <span className="text__dark">
                  {received(detail?.created_at)} mins ago
                </span>
              </span>
            </div>
            <div className="border__line"></div>
            <div className="detail__container orders__container">
              <span className="text__dark">Order Menu</span>
              <div className="product__cards">
                <div className="product__card">
                  {detail?.order_detail?.map((item, index) => (
                    <div className="product__card">
                      <div className="product__detail">
                        <div className="left__side">
                          <img
                            className="square__img"
                            src="https://picsum.photos/200/200"
                            alt=""
                          />
                          <div className="quantity__detail">
                            <span className="text__light">
                              {item?.product?.title?.[selectedLanguage]}
                            </span>
                            <span className="text__dark">
                              Kr.{item?.price}x{item?.number_of_items}
                            </span>
                          </div>
                        </div>
                        <span className="text__dark">
                          Kr.{item?.price * item?.number_of_items}
                        </span>
                      </div>
                      <div className="product__accessories">
                        {item?.order_accessory.map((item) => (
                          <div className="accessory__li">
                            <div className="accessory__detail">
                              <div className="circle" />
                              <span className="text__dark">
                                {item?.accessory?.title?.[selectedLanguage]}
                                <span className="text__normal">
                                  {" "}
                                  Kr. {item?.price}x {item?.number}
                                </span>
                              </span>
                            </div>
                            <span className="text__normal">
                              Kr.{item?.price * item?.number}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="border__line"></div>
            <div className="detail__container price__container">
              <div className="price__div">
                <span className="text__dark">Sub Total</span>
                <span className="text__light">
                  Kr. {detail?.amount + detail?.discount}
                </span>
              </div>
              <div className="price__div">
                <span className="text__dark">Discont</span>
                <span className="text__light">Kr. {detail?.discount}</span>
              </div>
              <div className="price__div">
                <span className="text__dark">Total</span>
                <span className="text__light">Kr. {detail?.amount}</span>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TableOrders;
