import { Button, TextField, Fab, Box } from '@mui/material';
import { selectedDeliveryPostCodeSelector, setSelectedDeliveryPostCode, selectedCompanySelector, setSelectedCompany } from 'app/rkt_query/SelectedStuffReducer';
import { BLUE_PRINTS, useAddDeliveryPostCodeMutation, useGetDeliveryPostCodeQuery, useUpdateDeliveryPostCodeMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation, { MultiDZTextValidation } from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';
import SnackAndBackDrop from 'app/DZcomponents/SnackAndBackDrop';
import { useSnackBarManager } from 'app/hooks/useSnackBarManager';



const AddUpdateDeliveryPostCode = ({ onClose }) => {
  const { fnShowSnackBar } = useSnackBarManager();
  const dispatch = useDispatch();
  const selectedDeliveryPostCode = useSelector(selectedDeliveryPostCodeSelector);
  const { data: res_get, isLoading: isLoadingDeliveryPostCode, error: errorDeliveryPostCode } = useGetDeliveryPostCodeQuery(selectedDeliveryPostCode?.id);

  const [updateDeliveryPostCode, responseUpdate,] = useUpdateDeliveryPostCodeMutation();
  const [addDeliveryPostCode, responseAdd,] = useAddDeliveryPostCodeMutation();
  const bluePrint = BLUE_PRINTS.deliveryPostCode;
  const [deliveryPostCodeState, setDeliveryPostCodeState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [open, setOpen] = useState(false);




  const addUpdateDeliveryPostCodeData = async (action) => {
    const formData = new FormData();
    formData.append('id', res_get?.data?.post_code?.id);
    formData.append('postcode', deliveryPostCodeState.postcode);
    formData.append('price', deliveryPostCodeState.price);
    const res = action === "add" ? await addDeliveryPostCode(formData) : await updateDeliveryPostCode(formData);
    if(res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done Successfully")
    } else{
      fnShowSnackBar(res?.error?.data?.message || "Something went wrong", true)
    }
    setOpen(true);
  }

    //this code is use to control infinity loops and backend required error. custom hook
    const {error,resetCount}= useResponseError(responseAdd,responseUpdate);

  useEffect(() => {


    setDeliveryPostCodeState(selectedDeliveryPostCode ? res_get?.data?.post_code : bluePrint);



  }, [res_get])


 

  return (
    <div>

      {/* {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />} */}
      {/* <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} /> */}
      <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />


      {deliveryPostCodeState ?
        <div style={addUpdateDivStyleGlobal}>

   


          <DZTextValidation error={error?.[0]} label="Post Code" variant="outlined" value={deliveryPostCodeState?.postcode}
            onChange={(e) => { setDeliveryPostCodeState(x => ({...x,postcode:e.target.value })) }}
            placeholder="Post Code"

          />
         <Grid item xs={12} md={12} lg={12}>
                          <Div sx={{ mr: 2 }}>
                            <TextField
                              fullWidth
                              label="Amount"
                              type="number"
                              variant="outlined"
                              noOfRows={1}
                              value={deliveryPostCodeState?.price}
                              onChange={(e) => {
                                setDeliveryPostCodeState((x) => ({
                                  ...x,
                                  price: e.target.value,
                                }));
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Div>
                        </Grid>
          <div style={dialogButtonsStyle} >
            <Button variant="outlined" onClick={() => { onClose(); dispatch(setSelectedDeliveryPostCode(null)); }}>Cancel</Button>
            {
              selectedDeliveryPostCode?.id ? <Button variant="contained" onClick={() => { addUpdateDeliveryPostCodeData(); setShowBackDrop(true); resetCount() }}>Update</Button>
                : <Button variant="contained" onClick={() => { addUpdateDeliveryPostCodeData("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedDeliveryPostCode(null)); }}>Add</Button>
            }
        
          </div>
      
        </div>
        : <div>Loading....</div>
      }
  


    </div>
  )
}

export default AddUpdateDeliveryPostCode