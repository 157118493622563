import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedRestaurantTableSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetRestaurantQuery, useGetRestaurantTableQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';

import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
import QRCode from 'react-qr-code';
import generatePDF from 'react-to-pdf';
import './table.css';

const ViewRestaurantTable = ({ selectId }) => {

    const { hideDialog } = useJumboDialog();

    const selectedRestaurantTable = useSelector(selectedRestaurantTableSelector);
    const [RestaurantTableId, setRestaurantTableId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingRestaurantTable, error: errorRestaurantTable } = useGetRestaurantTableQuery(RestaurantTableId);
    const { data: restaurant, isLoading: isLoadingRestaurant, error: errorRestaurant } = useGetRestaurantQuery(1);

    useEffect(() => {

        setRestaurantTableId(selectedRestaurantTable ? selectedRestaurantTable?.id : selectId)
    }, [])
    const restaurantTableArray =
        [
            { label: 'Table Number', data: res_get?.data?.restaurant_table?.table_number },
            { label: 'Table Code', data: res_get?.data?.restaurant_table?.table_code },
            { label: 'Restaurant', data: restaurant?.data?.restaurant?.title },

        ]

    const targetRef = useRef();

    return (
        <div>

            {
                errorRestaurantTable ? <div>{JSON.stringify(errorRestaurantTable)}</div>
                    : isLoadingRestaurantTable ? <div>Loading Single RestaurantTable.....</div>
                        : res_get?.data?.restaurant_table ?
                            <>

                                <div>
                                    <div>
                                        <Button variant="outlined" startIcon={<PictureAsPdfIcon />}
                                            onClick={() => generatePDF(targetRef, { filename: 'page.pdf' })}
                                        >
                                            Download PDF
                                        </Button>

                                        <div ref={targetRef}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    'justify-content': 'center',
                                                    'font-size': 'x-large'
                                                }}
                                            >
                                                <h1>Scan to order</h1>
                                            </div>
                                            <div
                                                style={{
                                                    height: "auto",
                                                    margin: "0 auto",
                                                    maxWidth: 300,
                                                    width: "100%",
                                                }}
                                            >
                                                {<QRCode
                                                    size={600}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={Config.tableOrderLink + '?table_number=' + res_get?.data?.restaurant_table?.table_number + "&table_id=" + res_get?.data?.restaurant_table?.id}
                                                    viewBox={`0 0 300 300`}
                                                />}

                                                <div

                                                    className="circle"
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        'flex-direction': 'column',
                                                        'align-items': 'center',
                                                        'font-size': 'large',
                                                        'justify-content': 'center',
                                                    }}

                                                >

                                                    <div>{'Table'}</div>
                                                    <div

                                                        style={{
                                                            display: 'flex',
                                                            'justify-content': 'center',
                                                            'font-size': 'x-large',
                                                            'align-items': 'center',
                                                        }}
                                                    >{res_get?.data?.restaurant_table?.table_number}</div>
                                                    <div>{'--------------------'}</div>
                                                    <div>{'Code'}</div>
                                                    <div  style={{
                                                            display: 'flex',
                                                            'justify-content': 'center',
                                                            'font-size': 'x-large',
                                                            'align-items': 'center',
                                                        }}
                                                    >{res_get?.data?.restaurant_table?.table_code}</div>
                                                </div>

                                                <div

                                                    style={{
                                                        display: 'flex',
                                                        'justify-content': 'center',
                                                        'font-size': 'x-large',
                                                        'align-items': 'center',
                                                        'margin-top': '20px',
                                                    }}
                                                >{'power by DZFood'}</div>
                                            </div>
                                        </div>

                                <Button variant="outlined" startIcon={<CloseIcon />}
                                            onClick={() => hideDialog()}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </div>


                                {/* <DZGenericView obj={restaurantTableArray} closeBtn={true} />  */}
                            </>
                            :
                            <>
                                <div>Bad Code</div>
                            </>

            }

        </div>
    )
}




export default ViewRestaurantTable

