import { Button, } from '@mui/material';
import { selectedAccessorySelector, setSelectedAccessory, } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector, } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddAccessoryMutation, useGetAccessoryQuery, useUpdateAccessoryMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import { MultiDZTextValidation } from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';
import SnackAndBackDrop from 'app/DZcomponents/SnackAndBackDrop';
import { useSnackBarManager } from 'app/hooks/useSnackBarManager';



const AddUpdateAccessory = ({ onClose }) => {

  const dispatch = useDispatch();
  const selectedAccessory = useSelector(selectedAccessorySelector);
  const { data: res_get, isLoading: isLoadingAccessory, error: errorAccessory } = useGetAccessoryQuery(selectedAccessory?.id);

  const [updateAccessory, responseUpdate,] = useUpdateAccessoryMutation();
  const [addAccessory, responseAdd,] = useAddAccessoryMutation();
  const bluePrint = BLUE_PRINTS.accessory;

  const { fnShowSnackBar } = useSnackBarManager();

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();

  const [accessoryState, setAccessoryState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [open, setOpen] = useState(false);

  const validationRules = {
    title: {
      required: true,
    }


  };

  const addUpdateAccessoryData = async (action) => {

    const isValid = validateForm();


    if (isValid) {
      const formData = new FormData();
      formData.append('id', res_get?.data?.accessory?.id);
      formData.append('title', JSON.stringify(accessoryState.title));
      const res = action === "add" ? await addAccessory(formData) : await updateAccessory(formData);
      
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done Successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message || "Something went wrong", true);
    }
      setOpen(true);
    }
  }


  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);


  // validation
  const [formErrors, setFormErrors] = useState({});
  const validateForm = () => {
    const errors = {};

    Object.keys(validationRules).forEach((field) => {
      const rules = validationRules[field];

      // Check if the field is multilingual or not
      const isMultilingual = typeof accessoryState[field] === 'object';

      if (isMultilingual) {
        // Multilingual field
        // Object.keys(LANGUAGES).forEach((language) => {
        // const languageField = `${field}.${language}`;
        const languageField = `${field}`;


        if (rules.required && !accessoryState[field]?.en) {
          console.log(languageField);
          errors[languageField] = ` ${field} is required`;
        }

        // Add more validation rules if needed for each language
        //{ commentedCode here ....}

      } else {
        // Non-multilingual field
        if (rules.required && !accessoryState[field]) {
          errors[field] = `${field} is required`;
        }
        // Add more validation rules if needed for the non-multilingual field
        //{ commentedCode here ....}
      }
    });
    console.log(errors);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    setAccessoryState(selectedAccessory ? res_get?.data?.accessory : bluePrint);
  }, [res_get])



  const status = ['active', 'in-active']
  return (
    <div>

      {/* {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />} */}
      {/* <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} /> */}

      <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />
    
      {accessoryState ?
        <div style={addUpdateDivStyleGlobal}>

          {/* image start */}
          {/* { commentedCode here ....} */}

          <MultiDZTextValidation error={formErrors?.title} label="Title" variant="outlined" value={accessoryState?.title}
            onChange={(e) => { setAccessoryState(x => ({ ...x, title: { ...x.title, [selectedLanguage]: e.target.value } })) }}
            placeholder="Name"

          />

          {/* { commentedCode here ....} */}
         
          <div style={dialogButtonsStyle} >
            <Button variant="outlined" onClick={() => { onClose(); dispatch(setSelectedAccessory(null)); }}>Cancel</Button>
            {
              selectedAccessory?.id ? <Button variant="contained" onClick={() => { addUpdateAccessoryData(); setShowBackDrop(true); resetCount() }}>Update</Button>
                : <Button variant="contained" onClick={() => { addUpdateAccessoryData("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedAccessory(null)); }}>Add</Button>
            }
            {/* dispatch(setSelectedAccessory(null)); */}
          </div>
        </div>
        : <div>Loading....</div>
      }
               {/* { commentedCode here ....} */}
    </div>
  )
}

export default AddUpdateAccessory