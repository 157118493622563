import Box from '@mui/material/Box';
import React from 'react'


export const DZColumn = ({children, style, noFlex, center}) => {
    return (
        <Box style={{
            ...style
        }

        }>
        {children}
        </Box>
    )
}

export const DZRow = ({children, style, center, noFlex}) => {
    return (
        <Box style={{ 
            display:'flex', 
            flexDirection:"row",
            justifyContent:'space-between', 
            ...style 
            }
          
        }>
        {children}
        </Box>
    )
}




