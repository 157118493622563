import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material';
import { LANGUAGES, selectedLanguageSelector, } from 'app/rkt_query/SettingsReducer';
import {selectedProductAccessorySelector,} from "app/rkt_query/SelectedStuffReducer";
import {
    BLUE_PRINTS, useGetAllProductAccessoriesQuery, useAddProductAccessoryMutation,useGetProductAccessoryQuery,useUpdateProductAccessoryMutation
    // useGetCourseQuery
} from 'app/rkt_query/storeApis';
import { useSelector, useDispatch } from 'react-redux';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import DZDropdown from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';
import SnackAndBackDrop from 'app/DZcomponents/SnackAndBackDrop';


const AddProductAccessory = ({ selectedId }) => {
    const dispatch = useDispatch();
    
    // const selectedCompany = useSelector(selectedCompanySelector);
    const { data: res_get_all} = useGetAllProductAccessoriesQuery(selectedId);
    const [selectedAccessoryIds, setSelectedAccessoryIds] = useState([]);
    const selectedProductAccessory = useSelector(selectedProductAccessorySelector);
    const {   data: res_get,isLoading: isLoadingProductAccessory, error: errorProductAccessory, } = useGetProductAccessoryQuery(selectedProductAccessory?.id);
    const [addProductAccessories, responseAdd,] = useAddProductAccessoryMutation();
    const [updateProductAccessory, responseUpdate,] = useUpdateProductAccessoryMutation();
    const bluePrint = BLUE_PRINTS.productAccessory;
    const [productAccessoryState, setProductAccessoryState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [open, setOpen] = useState(false);





   //this code is use to control infinity loops and backend required error. custom hook
   const {error,resetCount}= useResponseError(responseAdd,responseUpdate);

    useEffect(() => {


        setProductAccessoryState(selectedProductAccessory? res_get?.data?.product_accessory: { ...bluePrint, product_id: selectedId??'' });

    }, [res_get])




    const productAccessory = res_get_all?.data?.product_accessories?.map((e) => ({ ...productAccessoryState, accessory_id: e?.accessory_id }))

    const unRegAccessories = res_get_all?.data?.accessories?.filter(u => !productAccessory.some(c => c.accessory_id == u.id));
    const unRegAccessoriesTranslatable = unRegAccessories?.map((accessory) => (BLUE_PRINTS.accessory.translate(accessory, selectedLanguage)))


    return (
        <div>
      
      <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />

            {/* {showBackDrop && (<SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate}/>)} */}
      {/* <Snackbar  responseAdd={ responseAdd?.isSuccess === true ? responseAdd : responseUpdate }/> */}
            {productAccessoryState ?
                <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', gap: 25 }}>

                {!selectedProductAccessory?
                  
                <DZDropdown
            fieldTextName="title"
                  value={productAccessoryState?.accessory_id}
                  data={unRegAccessoriesTranslatable}
                  onChange={(e) => {
                    setProductAccessoryState((x) => ({
                      ...x,
                      accessory_id: e.target.value,
                    }));
                  }}
                  placeholder="Select Accessory"
                />    
                     :      
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                        <label style={{ fontSize: "1em", fontWeight: 500 }}>{"Accessory Name"}</label>
                        <label style={{ fontSize: "1em" }}>  {res_get?.data?.product_accessory?.accessory?.title[selectedLanguage]}</label>
                      
                    </div>
                        }
                                  {/* {commentedCode here....} */}

                <TextField  label="Default" variant="outlined" type='number'
                  value={productAccessoryState?.number}
                  onChange={(e) => {
                    setProductAccessoryState(x => ({ ...x, number: e.target.value, }))
                  }}
                />
                <TextField  label="Max Qty" variant="outlined" type='number'
                  value={productAccessoryState?.max}
                  onChange={(e) => {
                    setProductAccessoryState(x => ({ ...x, max: e.target.value, }))
                  }}
                />
                <DZTextValidation error={error?.[0]} label="Price" variant="outlined"
                  value={productAccessoryState?.price}
                  onChange={(e) => {
                    setProductAccessoryState(x => ({ ...x, price: e.target.value, }))
                  }}
                />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }} >
                        <Button variant="outlined" onClick={() => { hideDialog();  dispatch(setProductAccessoryState(null)); }}>Cancel</Button>
                        {res_get?.data?.product_accessory?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                    updateProductAccessory(productAccessoryState); setShowBackDrop(true);resetCount(); dispatch(setProductAccessoryState(null)); }}
              >  Update
              </Button>
            ) : (
                <Button variant="contained" onClick={() => { addProductAccessories(productAccessoryState); setShowBackDrop(true);  resetCount(); }}>Add</Button>
            )}
                    </div>

                </div>
                : <div>Loading....</div>
            }
        </div>
    )
}

export default AddProductAccessory