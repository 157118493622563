import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Fab } from "@mui/material";
import { IMaskInput } from "react-imask";

import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Input from "@mui/material/Input";
import Div from "@jumbo/shared/Div";
import PropTypes from "prop-types";
import {
  BLUE_PRINTS,
  useAddUpdateRestaurantOpeningHourMutation,
  useGetRestaurantOpeningHourQuery,
} from "app/rkt_query/storeApis";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Snackbar from "@mui/material/Snackbar";
import {
  List,
  ListItem,
  Avatar,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const UpdateViewRestaurantTime = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(true);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [error, setError] = useState([]);
  const {
    data: res_get,
    isLoading: isLoadingRestaurantOpeningHour,
    error: errorRestaurantOpeningHour,
  } = useGetRestaurantOpeningHourQuery();
  const [updateRestaurantOpeningHour, responseUpdate] =
    useAddUpdateRestaurantOpeningHourMutation();
  const [openingHours, setOpeningHours] = useState({
    sun_from: "",
    sun_to: "",
    mon_from: "",
    mon_to: "",
    tue_from: "",
    tue_to: "",
    wed_from: "",
    wed_to: "",
    thu_from: "",
    thu_to: "",
    fri_from: "",
    fri_to: "",
    sat_from: "",
    sat_to: "",
  });
  const countZero = () => {
    setCount(true);
  };

  if (responseUpdate?.error?.data?.errors && count) {
    setCount(false);
    setError(responseUpdate?.error?.data?.errors);
  }
  const handleChange = (e) => {
    
    console.log(e.target.value);
    setOpeningHours({
      ...openingHours,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setOpeningHours(res_get?.data?.openingHours);
  }, [res_get]);

  // const saveChanges = async () => {
  //   setLoading(true)

  //       updateRestaurantOpeningHour().unwrap()
  //         .then((payload) => {
  //              setLoading(false);
  //              setOpen(true);

  //             });

  //     }

  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    updateRestaurantOpeningHour(openingHours)
      .unwrap()
      .then((payload) => {
        setLoading(false);
        setOpen(true);
      });
      setLoading(false);
    console.log(openingHours);
  };

  return (
    <div>
      {showBackDrop && <SimpleBackdrop responseUpdate={responseUpdate} />}
      <Snackbar
        sx={{ mt: 8 }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleToClose}
      >
        <Alert
          onClose={handleToClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {responseUpdate?.data?.message}
        </Alert>
      </Snackbar>
      <JumboDemoCard
        title={"Restaurant Timing"}
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
      >
        <form onSubmit={handleSubmit}>
          {/* <Div sx={{ mb: 0.5, mt: 1 }}></Div> */}
          <Grid container spacing={2}>
            {/* for sunday */}

            <Grid item xs={3} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1, ml: 30 }}>
                <h3>Sunday</h3>
              </Div>
            </Grid>
            <Grid item xs={3} md={4} lg={4}>
              <TextField
                label="From"
                name="sun_from"
                value={openingHours?.sun_from}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} md={4} lg={4}>
              <TextField
                label="To"
                name="sun_to"
                value={openingHours?.sun_to}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* for monday */}
            <Grid item xs={3} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1, ml: 30 }}>
                <h3>Monday</h3>
              </Div>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="From"
                name="mon_from"
                value={openingHours?.mon_from}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="To"
                name="mon_to"
                value={openingHours?.mon_to}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* for tuesday */}
            <Grid item xs={3} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1, ml: 30 }}>
                <h3>Tuesday</h3>
              </Div>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="From"
                name="tue_from"
                value={openingHours?.tue_from}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="To"
                name="tue_to"
                value={openingHours?.tue_to}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* for wednesday */}
            <Grid item xs={3} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1, ml: 30 }}>
                <h3>Wednesday</h3>
              </Div>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="From"
                name="wed_from"
                value={openingHours?.wed_from}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="To"
                name="wed_to"
                value={openingHours?.wed_to}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* for thursday */}
            <Grid item xs={3} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1, ml: 30 }}>
                <h3>Thursday</h3>
              </Div>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="From"
                name="thu_from"
                value={openingHours?.thu_from}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="To"
                name="thu_to"
                value={openingHours?.thu_to}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* for friday */}
            <Grid item xs={3} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1, ml: 30 }}>
                <h3>Friday</h3>
              </Div>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="From"
                name="fri_from"
                value={openingHours?.fri_from}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="To"
                name="fri_to"
                value={openingHours?.fri_to}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* for saturday */}
            <Grid item xs={3} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1, ml: 30 }}>
                <h3>Saturday</h3>
              </Div>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="From"
                name="sat_from"
                value={openingHours?.sat_from}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="To"
                name="sat_to"
                value={openingHours?.sat_to}
                onChange={handleChange}
                InputProps={{
                  inputComponent: IMaskInput,
                  inputProps: { mask: "00:00" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <ListItem
            alignItems="flex-start"
            sx={{ p: (theme) => theme.spacing(0.5, 3) }}
          >
            <ListItemIcon
              sx={{ minWidth: 36, color: "text.secondary" }}
            ></ListItemIcon>
            <ListItemText
              secondary={
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  onClick={() => {
                    handleSubmit();
                    setShowBackDrop(true);
                    countZero();
                  }}
                  loading={loading}
                >
                  Update
                </LoadingButton>
              }
            />
          </ListItem>
        </form>
      </JumboDemoCard>
    </div>
  );
};

export default UpdateViewRestaurantTime;
