import React, { useState, useEffect } from 'react'
import { Grid, FormControlLabel, Radio } from "@mui/material";
import { useGetCountCustomersQuery, useGetYearlyCustomersQuery, useGetMonthlyCustomersQuery, } from "app/rkt_query/storeApis";
import Chart from '@jumbo/dz-components/chart/Chart';
import FilesCounterCard from '../../shared/widgets/FilesCounterCard/FilesCounterCard';
import DZDropdown from '../../../@jumbo/dz-components/dropdowns/DZDropdown';

const CustomerChart = () => {

    const [selectedOption, setSelectedOption] = useState('yearly');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedMonthYear, setSelectedMonthYear] = useState();
    const [customers, setCustomers] = useState()
    const [countYearly, setCountYearly] = useState(new Date().getFullYear())
    const [countMonthly, setCountMonthly] = useState(null)

    let months = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
    ];

    let currentYear = new Date().getFullYear();
    const years = [
        { id: currentYear - 3, name: currentYear - 3 },
        { id: currentYear - 2, name: currentYear - 2 },
        { id: currentYear - 1, name: currentYear - 1 },
        { id: currentYear, name: currentYear }
    ]

    const handleOptionChange = (event) => {

        let selectedOpt = event.target.value;

        if (selectedOpt === 'yearly') {
            setSelectedYear(currentYear);
            setCountYearly(currentYear);
            setCountMonthly(null);
        }
        else if (selectedOpt === 'monthly') {
            setSelectedMonth(months.find(month => month.id === new Date().getMonth() + 1).id);
            setSelectedMonthYear(currentYear);
            setCountMonthly(months.find(month => month.id === new Date().getMonth() + 1).id);
            //changing value to clear currentYear cache
            setSelectedYear(currentYear + 1);
            setCountYearly(currentYear);
        }

        setSelectedOption(selectedOpt);

    };

    useEffect(() => {
        setSelectedOption('yearly');
        setSelectedYear(currentYear);
    }, []);

    const { data: res_get_all, isLoading: isLoadingYearlyCustomers, error: errorYearlyCustomers } = useGetYearlyCustomersQuery(selectedYear);
    const { data: monthly_Customers, isLoading: isLoadingMonthlyCustomers, error: errorMonthlyCustomers } = useGetMonthlyCustomersQuery({ year: selectedMonthYear, month: selectedMonth });
    const { data: res_get_all_customers_count, isLoading: isLoadingCustomersCount, error: errorCustomersCount } = useGetCountCustomersQuery({ year: countYearly, month: countMonthly });

    useEffect(() => {
        setCustomers(res_get_all?.data?.customers);
    }, [res_get_all])

    useEffect(() => {
        setCustomers(monthly_Customers?.data?.customers);
    }, [monthly_Customers])

    let data = [];

    if (customers) {

        if (selectedOption === 'yearly') {
            months.forEach(month => {
                //debugger;
                let monthCustomers = customers[month.name] || [];

                let pending = 0, complete = 0, cancelled = 0, total = 0;

                total = monthCustomers.length

                // monthCustomers.forEach(customer => {
                //     switch (customer.order_status) {
                //         case 'pending':
                //             pending++;
                //             break;
                //         case 'completed':
                //             complete++;
                //             break;
                //         case 'cancelled':
                //             cancelled++;
                //             break;
                //     }
                //     total++;
                // });

                data.push({
                    name: month.name,
                    // pending: parseInt(pending),
                    // complete: parseInt(complete),
                    // cancelled: parseInt(cancelled),
                    total: total,
                });
            });
        }

        if (selectedOption === 'monthly') {

            let daysInMonth = new Date(selectedMonthYear, selectedMonth, 0).getDate();

            let days = [];
            for (let i = 1; i <= daysInMonth; i++) {
                days.push(i);
            }

            days.forEach(day => {

                let monthCustomers = customers[day] || [];

                let pending = 0, complete = 0, cancelled = 0, total = 0;

                monthCustomers.forEach(customer => {
                    switch (customer.order_status) {
                        case 'pending':
                            pending++;
                            break;
                        case 'completed':
                            complete++;
                            break;
                        case 'cancelled':
                            cancelled++;
                            break;
                    }
                    total++;
                });

                data.push({
                    name: day,
                    // pending: parseInt(pending),
                    // complete: parseInt(complete),
                    // cancelled: parseInt(cancelled),
                    total: parseInt(total),
                });
            });
        }

    }

    //for rendering of Chart dataKey
    //here [value] of dataKey is the hex color of chart dataKey 
    let dataKey = {
        'total' : '#e6797a',
    }

    // const maxValue = Math.max(...data.map(({ pending, complete, cancelled }) => Math.max(pending, complete, cancelled)));
    const maxValue = Math.max(...data.map(({ total }) => Math.max(total)));
    const domain = [0, maxValue + 6]; // add extra 6 to the maximum value

    return (
        <>
            <div style={{ width: '100%', marginBottom: '16px' }}>
                <FormControlLabel
                    control={
                        <Radio
                            checked={selectedOption === 'yearly'}
                            onChange={handleOptionChange}
                            value="yearly"
                        />
                    }

                    label="Yearly"
                    sx={{ ml: "16px" }} // Adjust the margin value as needed
                />

                <FormControlLabel
                    control={
                        <Radio
                            checked={selectedOption === 'monthly'}
                            onChange={handleOptionChange}
                            value="monthly"
                        />
                    }
                    label="Monthly"
                    sx={{ ml: "16px" }} // Adjust the margin value as needed
                />

                {selectedOption === 'yearly' && (
                    <DZDropdown
                        value={selectedYear}
                        data={years}
                        onChange={(e) => {
                            setSelectedYear(e.target.value);
                            setCountYearly(e.target.value);
                        }}
                        placeholder="Select Year"
                    />
                )}
                {selectedOption === 'monthly' && (
                    <div style={{ display: 'flex', width: '100%' }}>
                        <DZDropdown
                            value={selectedMonthYear}
                            data={years}
                            minWidth='50%'
                            cssStyle={{ padding: 1 }}
                            onChange={(e) => {
                                setSelectedMonthYear(e.target.value);
                                setCountYearly(e.target.value);
                            }}
                            placeholder="Select Year"
                        />
                        <DZDropdown
                            value={selectedMonth}
                            data={months}
                            minWidth='50%'
                            cssStyle={{ padding: 1 }}
                            onChange={(e) => {
                                setSelectedMonth(e.target.value);
                                setCountMonthly(e.target.value);
                            }}
                            placeholder="Select Month"
                        />
                    </div>
                )}
            </div>

            <Grid container spacing={2}>

                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_customers_count?.data?.customers?.total} title={"Total New Customers"} IconName={'tasksIcon.svg'} bgColor={'#e6797a'} />
                </Grid>
                {/* <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_customers_count?.data?.customers?.preCustomers} title={"Pre Customers"} IconName={'preCustomerIcon.svg'} bgColor={'#FF9C61'} />
                </Grid>

                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_customers_count?.data?.customers?.pending} title={"Pending Customers"} IconName={'pendingIcon.svg'} bgColor={'#ffc658'} />
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_customers_count?.data?.customers?.inProcess} title={"InProcess Customers"} IconName={'inProcessIcon.svg'} bgColor={'#00b7eb'} />
                </Grid>

                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_customers_count?.data?.customers?.completed} title={"Complete Customers"} IconName={'completedIcon.svg'} bgColor={'#23BCBA'} />
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_customers_count?.data?.customers?.cancelled} title={"Cancelled Customers"} IconName={'cancelCustomerIcon.svg'} bgColor={'#da393a'} />
                </Grid> */}

                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Chart data={data} domain={domain} dataKey={dataKey} title={selectedOption == 'yearly' ? selectedYear + ' Customers' : months.find(month => month.id === selectedMonth).name + ' ' + selectedMonthYear + ' Customers'} />
                </Grid>
                <br></br>
            </Grid>
        </>

    )
}

export default CustomerChart

