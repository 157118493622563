import { createSlice } from "@reduxjs/toolkit";
export const slice = createSlice({
  name: "SnackBarReducer",
  initialState: {
    message: null,
    status:false,
  },
  reducers: {
    setSnackBarMessage: (state, action) => {
      state.message = action.payload;
    },
    setSnackBarStatus: (state, action) => {
      state.status = action.payload;
    },
    hideSnackBarMessage: (state) => {
      state.message = null;
      state.status = false; 
    },
    setSnackBarStatusNull: (state) => {
      state.status = false; 
    },
    
  },
});
export const { setSnackBarMessage,setSnackBarStatus,hideSnackBarMessage,setSnackBarStatusNull } = slice.actions;
export const snackBarMessageSelector = (state) => state.SnackBarReducer.message;
export const snackBarStatusSelector = (state) => state.SnackBarReducer.status;
export default slice.reducer; 