import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./app/App";
import "./app/config/i18n";

ReactDOM.render(
  <React.StrictMode>
    <>

      <App />
    </>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
