import { selectedPoscAdminSelector } from 'app/rkt_query/SelectedStuffReducer';
import { BLUE_PRINTS, useGetPoscAdminQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewPoscAdmin = ({ selectId }) => {
    const selectedPoscAdmin = useSelector(selectedPoscAdminSelector);

    console.log(selectedPoscAdmin)
    
    
    const { data: res_get, isLoading: isLoadingPoscAdmin, error: errorPoscAdmin } = useGetPoscAdminQuery(selectedPoscAdmin?.id);
    
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.posc_admin?.image);
    useEffect(() => {
        res_get?.data?.posc_admin?.image && setSelectedFile(res_get?.data?.posc_admin?.image)
    }, [])

 

    const posc_adminArray =
    [
        // { label: 'Restaurant', data: res_get?.data?.posc_admin?.restaurant?.name },
        { label: 'Name', data: res_get?.data?.posc_admin?.name },
        { label: 'Email', data: res_get?.data?.posc_admin?.email },
       


    ]
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {errorPoscAdmin ? <div>{JSON.stringify(errorPoscAdmin)}</div>
                : isLoadingPoscAdmin ? <div>Loading Single Posc Admin.....</div>
                    : res_get?.data?.posc_admin ?
                        <DZGenericView obj={posc_adminArray} closeBtn={true} /> 
                        : <div>Bad Code</div>}
                             {/* {commentedCode here....} */}

        </div>
    )
}

export default ViewPoscAdmin