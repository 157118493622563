import React, { useState, useEffect } from 'react'
import { Grid, FormControlLabel, Box, FormControl, InputLabel, Radio } from "@mui/material";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import { useGetCurrentYearOrdersQuery, useGetCountOrdersQuery, useGetYearlyOrdersQuery, useGetMonthlyOrdersQuery } from "app/rkt_query/storeApis";
import Chart from '@jumbo/dz-components/chart/Chart';
import LineChartOrder from '@jumbo/dz-components/chart/LineChartOrder';
import ProjectCounterCard from '../../shared/widgets/ProjectCounterCard/ProjectCounterCard';
import TasksCounterCard from '../../shared/widgets/TasksCounterCard/TasksCounterCard';
import FilesCounterCard from '../../shared/widgets/FilesCounterCard/FilesCounterCard';
import TeamsCounterCard from '../../shared/widgets/TeamsCounterCard/TeamsCounterCard';
import SalesStatistics from '../../../@jumbo/dz-components/SalesStatistics/SalesStatistics';
import DZDropdown from '../../../@jumbo/dz-components/dropdowns/DZDropdown';

const OrderChart = () => {

    const [selectedOption, setSelectedOption] = useState('yearly');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedMonthYear, setSelectedMonthYear] = useState();
    const [countYearly, setCountYearly] = useState(new Date().getFullYear())
    const [countMonthly, setCountMonthly] = useState(null)

    let months = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
    ];

    let currentYear = new Date().getFullYear();
    const years = [
        { id: currentYear - 3, name: currentYear - 3 },
        { id: currentYear - 2, name: currentYear - 2 },
        { id: currentYear - 1, name: currentYear - 1 },
        { id: currentYear, name: currentYear }
    ]

    const handleOptionChange = (event) => {

        let selectedOpt = event.target.value;

        if (selectedOpt === 'yearly') {
            setSelectedYear(currentYear);
            setCountYearly(currentYear);
            setCountMonthly(null);
        }
        else if (selectedOpt === 'monthly') {
            setSelectedMonth(months.find(month => month.id === new Date().getMonth() + 1).id);
            setSelectedMonthYear(currentYear);
            setCountMonthly(months.find(month => month.id === new Date().getMonth() + 1).id);
            //changing value to clear currentYear cache
            setSelectedYear(currentYear + 1);
            setCountYearly(currentYear);
        }

        setSelectedOption(selectedOpt);

    };

    useEffect(() => {
        setSelectedOption('yearly');
        setSelectedYear(currentYear);
    }, []);

    const { data: res_get_all, isLoading: isLoadingYearlyOrders, error: errorYearlyOrders } = useGetYearlyOrdersQuery(selectedYear);
    const { data: monthly_Orders, isLoading: isLoadingMonthlyOrders, error: errorMonthlyOrders } = useGetMonthlyOrdersQuery({ year: selectedMonthYear, month: selectedMonth });

    const { data: res_get_all_order_count, isLoading: isLoadingOrderCount, error: errorOrderCount } = useGetCountOrdersQuery({ year: countYearly, month: countMonthly });
    const [orders, setOrders] = useState()

    useEffect(() => {
        setOrders(res_get_all?.data?.orders)
        // setOrders(res_get_all?.data?.current_year_orders);
    }, [res_get_all])

    useEffect(() => {
        setOrders(monthly_Orders?.data?.orders)
        // setOrders(res_get_all?.data?.current_year_orders);
    }, [monthly_Orders])

    let data = [];
    //let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    if (orders) {

        if (selectedOption === 'yearly') {
            months.forEach(month => {

                let monthOrders = orders[month.name] || [];

                let pending = 0, complete = 0, cancelled = 0, total = 0;

                monthOrders.forEach(order => {
                    switch (order.order_status) {
                        case 'pending':
                            pending++;
                            break;
                        case 'completed':
                            complete++;
                            break;
                        case 'cancelled':
                            cancelled++;
                            break;
                    }
                    total++;
                });

                data.push({
                    name: month.name,
                    pending: pending,
                    complete: complete,
                    cancelled: cancelled,
                    total: total,
                });
            });
        }

        if (selectedOption === 'monthly') {

            let daysInMonth = new Date(selectedMonthYear, selectedMonth, 0).getDate();

            let days = [];
            for (let i = 1; i <= daysInMonth; i++) {
                days.push(i);
            }

            days.forEach(day => {

                let monthOrders = orders[day] || [];

                let pending = 0, complete = 0, cancelled = 0, total = 0;

                monthOrders.forEach(order => {
                    switch (order.order_status) {
                        case 'pending':
                            pending++;
                            break;
                        case 'completed':
                            complete++;
                            break;
                        case 'cancelled':
                            cancelled++;
                            break;
                    }
                    total++;
                });

                data.push({
                    name: day,
                    pending: pending,
                    complete: complete,
                    cancelled: cancelled,
                    total: total,
                });
            });
        }

    }

    //for rendering of Chart dataKey
    //here [value] of dataKey is the hex color of chart dataKey 
    let dataKey = {
        'pending' : '#ffc658',
        'completed' : '#82ca9d',
        'cancelled' : '#c9170e',
        'total' : '#e6797a',
    }

    const maxValue = Math.max(...data.map(({ pending, complete, cancelled }) => Math.max(pending, complete, cancelled)));
    const domain = [0, maxValue + 6]; // add extra 6 to the maximum value

    return (
        <>
            {/* <div style={{ marginBottom: '16px' }}> */}
            <div style={{ width: '100%', marginBottom: '16px' }}>
                <FormControlLabel
                    control={
                        <Radio
                            checked={selectedOption === 'yearly'}
                            onChange={handleOptionChange}
                            value="yearly"
                        />
                    }

                    label="Yearly"
                    sx={{ ml: "16px" }} // Adjust the margin value as needed
                />

                <FormControlLabel
                    control={
                        <Radio
                            checked={selectedOption === 'monthly'}
                            onChange={handleOptionChange}
                            value="monthly"
                        />
                    }
                    label="Monthly"
                    sx={{ ml: "16px" }} // Adjust the margin value as needed
                />

                {selectedOption === 'yearly' && (
                    <DZDropdown
                        value={selectedYear}
                        data={years}
                        onChange={(e) => {
                            setSelectedYear(e.target.value);
                            setCountYearly(e.target.value);
                        }}
                        placeholder="Select Year"
                    />
                )}
                {selectedOption === 'monthly' && (
                    <div style={{ display: 'flex', width: '100%' }}>
                        <DZDropdown
                            value={selectedMonthYear}
                            data={years}
                            minWidth='50%'
                            cssStyle={{ padding: 1 }}
                            onChange={(e) => {
                                setSelectedMonthYear(e.target.value);
                                setCountYearly(e.target.value);
                            }}
                            placeholder="Select Year"
                        />
                        <DZDropdown
                            value={selectedMonth}
                            data={months}
                            minWidth='50%'
                            cssStyle={{ padding: 1 }}
                            onChange={(e) => {
                                setSelectedMonth(e.target.value);
                                setCountMonthly(e.target.value);
                            }}
                            placeholder="Select Month"
                        />
                    </div>
                )}
            </div>

            <Grid container spacing={2}>
                {/*   
                   
       <Grid item xs={12} sm={12} md={12} lg={12}>
            <SalesStatistics monthly={res_get_all?.data}  />
                </Grid> */}
                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.total} title={"Total Orders"} IconName={'tasksIcon.svg'} bgColor={'#e6797a'} />
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.preOrder} title={"Pre Orders"} IconName={'preOrderIcon.svg'} bgColor={'#FF9C61'} />
                </Grid>

                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.pending} title={"Pending Orders"} IconName={'pendingIcon.svg'} bgColor={'#ffc658'} />
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.inProcess} title={"InProcess Orders"} IconName={'inProcessIcon.svg'} bgColor={'#00b7eb'} />
                </Grid>

                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.completed} title={"Complete Orders"} IconName={'completedIcon.svg'} bgColor={'#23BCBA'} />
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                    <FilesCounterCard count={res_get_all_order_count?.data?.orders?.cancelled} title={"Cancelled Orders"} IconName={'cancelOrderIcon.svg'} bgColor={'#da393a'} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12} md={12}>
                    <Chart data={data} domain={domain} dataKey={dataKey} title={selectedOption == 'yearly' ? selectedYear + ' Orders' : months.find(month => month.id === selectedMonth).name + ' ' + selectedMonthYear + ' Orders'} />
                </Grid>
                <br></br>
                {/* <Grid item xs={12} sm={12} lg={12} md={12}>
                <LineChartOrder data={data} domain={domain} />
            </Grid> */}
            </Grid>
        </>

    )
}

export default OrderChart

