import React from 'react'
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

const DZGenericView = ({ obj, image, imagePath, checkImage = false, closeBtn = false }) => {

    const { hideDialog } = useJumboDialog();
    console.log('imag', image);
    return (

        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
            {checkImage ? <DZImageCircle image={image} link={imagePath} style={{
                height: 100, width: 100, borderRadius: 50, alignSelf: "center",
            }}
            /> : ''}
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
                {obj.map((item) => (

                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                        <label style={{ fontSize: "1em", fontWeight: 500 }}>{item.label}</label>
                        <label style={{ fontSize: "1em" }}>{item.data}</label>
                    </div>
                ))
                }

            </div>
            {closeBtn ? <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>
            </div> : ''}
        </div>
    )
}

export default DZGenericView