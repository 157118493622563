import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Switch,
  FormControlLabel,
} from "@mui/material";

import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  useGetCurrentRestaurantFeatureQuery,
  useUpdateRestaurantFeatureMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@mui/material/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Config } from "constant";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  List,
  ListItem,
  Avatar,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import DZSwitch from "app/DZcomponents/DZSwitch";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const UpdateViewRestaurantFeatureInfo = () => {
  const { fnShowSnackBar } = useSnackBarManager();
  const [open, setOpen] = React.useState(false);
  const { data: res_get } = useGetCurrentRestaurantFeatureQuery();
  const [updateRestaurantFeature, responseUpdate] =
    useUpdateRestaurantFeatureMutation();
  const [restaurantFeatureState, setRestaurantFeatureState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [error, setError] = useState([]);
  const [count, setCount] = useState(true);

  const [loading, setLoading] = useState(false);

  const countZero = () => {
    setCount(true);
  };
  if (responseUpdate?.error?.data?.errors && count) {
    setCount(false);
    setError(responseUpdate?.error?.data?.errors);
  }

  const addUpdateRestaurantFeatureData = async () => {
    setLoading(true);

    const formData = new FormData();

    formData.append("id", res_get?.data?.restaurant?.id);
    formData.append(
      "table_reservation",
      restaurantFeatureState.table_reservation ? 1 : 0
    );
    formData.append("payment", restaurantFeatureState.payment ? 1 : 0);
    formData.append("delivery", restaurantFeatureState.delivery ? 1 : 0);
    formData.append("ssk", restaurantFeatureState.ssk ? 1 : 0);
    formData.append("user_web", restaurantFeatureState.user_web ? 1 : 0);
    formData.append("user_app", restaurantFeatureState.user_app ? 1 : 0);
    formData.append("pay_later", restaurantFeatureState.pay_later ? 1 : 0);

    await updateRestaurantFeature(formData)
      .unwrap()
      .then((payload) => {
        setLoading(false);
        setOpen(true);
        if (payload?.data?.message) {
          fnShowSnackBar(payload?.data?.message, "success");
        } else {
          
          fnShowSnackBar(payload?.error?.data?.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        fnShowSnackBar(error?.data?.message, "error");
      });
  };

  useEffect(() => {
    setRestaurantFeatureState(res_get?.data?.restaurant_feature);
  }, [res_get]);

  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };
  return (
    <>
      <div>
        {showBackDrop && <SimpleBackdrop responseUpdate={responseUpdate} />}
        <Snackbar
          sx={{ mt: 8 }}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleToClose}
        >
          <Alert
            onClose={handleToClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {responseUpdate?.data?.message}
          </Alert>
        </Snackbar>

        <JumboDemoCard
          title={"Restaurant Feature"}
          wrapperSx={{
            p: 0,
            backgroundColor: "background.paper",
            "&:last-child": { pb: 0 },
          }}
        >
          <div
            style={{
              marginTop: 25,
              display: "flex",
              flexDirection: "column",
              gap: 25,
              marginBottom: 60,
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <Box sx={{ flex: 1 }}>
                <Grid container spacing={3.75}>
                  <DZSwitch
                    checked={restaurantFeatureState?.table_reservation}
                    onChange={(e) => {
                      setRestaurantFeatureState((x) => ({
                        ...x,
                        table_reservation: e.target.checked,
                      }));
                    }}
                    label="Table Reservation"
                  />

                  <DZSwitch
                    checked={restaurantFeatureState?.payment}
                    onChange={(e) => {
                      setRestaurantFeatureState((x) => ({
                        ...x,
                        payment: e.target.checked,
                      }));
                    }}
                    label="Payment"
                  />
                  <DZSwitch
                    checked={restaurantFeatureState?.user_web}
                    onChange={(e) => {
                      setRestaurantFeatureState((x) => ({
                        ...x,
                        user_web: e.target.checked,
                      }));
                    }}
                    label="User Web"
                  />
                  <DZSwitch
                    checked={restaurantFeatureState?.user_app}
                    onChange={(e) => {
                      setRestaurantFeatureState((x) => ({
                        ...x,
                        user_app: e.target.checked,
                      }));
                    }}
                    label="User App"
                  />
                  <DZSwitch
                    checked={restaurantFeatureState?.ssk}
                    onChange={(e) => {
                      setRestaurantFeatureState((x) => ({
                        ...x,
                        ssk: e.target.checked,
                      }));
                    }}
                    label="SSK"
                  />
                  <DZSwitch
                    checked={restaurantFeatureState?.delivery}
                    onChange={(e) => {
                      setRestaurantFeatureState((x) => ({
                        ...x,
                        delivery: e.target.checked,
                      }));
                    }}
                    label="Delivery"
                  />
                  <DZSwitch
                    checked={restaurantFeatureState?.pay_later}
                    onChange={(e) => {
                      setRestaurantFeatureState((x) => ({
                        ...x,
                        pay_later: e.target.checked,
                      }));
                    }}
                    label="Pay Later"
                  />
                </Grid>
              </Box>
            </Box>

            <ListItem
              alignItems="flex-start"
              sx={{ p: (theme) => theme.spacing(0.5, 3) }}
            >
              <ListItemIcon
                sx={{ minWidth: 36, color: "text.secondary" }}
              ></ListItemIcon>
              <ListItemText
                secondary={
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    onClick={() => {
                      addUpdateRestaurantFeatureData();
                      setShowBackDrop(true);
                      countZero();
                    }}
                    loading={loading}
                  >
                    Update
                  </LoadingButton>
                }
              />
            </ListItem>
          </div>
        </JumboDemoCard>
      </div>
    </>
  );
};

export default UpdateViewRestaurantFeatureInfo;
