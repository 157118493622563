import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@mui/material";
import {
  selectedSizeGroupSelector,
  setSelectedSizeGroup,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddSizeGroupMutation,
  useDeleteSizeMutation,
  useGetSizeGroupQuery,
  useUpdateSizeGroupMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import DZLabel, {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DZDeleteDialog from "@jumbo/dz-components/dialog/DZDeleteDialog";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const validationRules = {
  title: {
    required: true,
  },
  short_title: {
    required: true,
  },
};

const AddUpdateSizeGroup = ({ onClose }) => {
  const fields = {
    id: "",
    title: LANGUAGES.defaultObject,
    short_title: LANGUAGES.defaultObject,
    default: 0,
  };
  const [deleteSize, responseDelete] = useDeleteSizeMutation();
  const dispatch = useDispatch();
  const selectedSizeGroup = useSelector(selectedSizeGroupSelector);
  const {
    data: res_get,
    isLoading: isLoadingSizeGroup,
    error: errorSizeGroup,
  } = useGetSizeGroupQuery(selectedSizeGroup?.id);
  const { fnShowSnackBar } = useSnackBarManager();
  const [updateSizeGroup, responseUpdate] = useUpdateSizeGroupMutation();
  const [addSizeGroup, responseAdd] = useAddSizeGroupMutation();
  const bluePrint = BLUE_PRINTS.sizeGroup;
  const [sizeGroupState, setSizeGroupState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [inputFields, setInputFields] = useState([fields]);

  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [index1, setIndex] = React.useState(null);
  const addUpdateSizeGroupData = async (action) => {
    const isValid = validateForm();

    if (isValid) {
      const formData = new FormData();
      formData.append("id", res_get?.data?.size_group?.id);
      formData.append("title", sizeGroupState.title);
      formData.append("short_title", sizeGroupState.short_title);
      formData.append("groupSizes", JSON.stringify(inputFields));
      action === "add"
        ? await addSizeGroup(formData)
            .unwrap()
            .then((res) => {
              console.log(res, "res");
              if (res?.data?.message) {
                fnShowSnackBar(
                  res?.data?.message || "Size Group Added Successfully"
                );
              } else {
                // fnShowSnackBar("Some thing went wrong", true);
              }
            })
        : await updateSizeGroup(formData)
            .unwrap()
            .then((res) => {
              console.log(res, "res");
              if (res?.data?.message) {
                fnShowSnackBar(
                  res?.data?.message || "Size Group Updated Successfully"
                );
              } else {
                // fnShowSnackBar("Some thing went wrong", true);
              }
            });
      setOpen(true);
    }
  };
  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  // validation
  const [formErrors, setFormErrors] = useState({});
  const validateForm = () => {
    const errors = {};

    Object.keys(validationRules).forEach((field) => {
      const rules = validationRules[field];

      // Check if the field is multilingual or not
      const isMultilingual = typeof sizeGroupState[field] === "object";

      if (isMultilingual) {
        // Multilingual field
        // Object.keys(LANGUAGES).forEach((language) => {

        // const languageField = `${field}.${language}`;
        const languageField = `${field}`;

        if (rules.required && !sizeGroupState[field]?.en) {
          console.log(languageField);
          errors[languageField] = ` ${field} is required`;
          // errors[languageField] = `${LANGUAGES[language]} ${field} is required`;
        }

        // Add more validation rules if needed for each language
        // if (rules.isNumber && isNaN(sizeGroupState[field]?.[language])) {
        //   errors[languageField] = `Please enter a valid number for ${LANGUAGES[language]} ${field}`;
        // }
        // });
      } else {
        // Non-multilingual field
        if (rules.required && !sizeGroupState[field]) {
          errors[field] = `${field} is required`;
        }

        // Add more validation rules if needed for the non-multilingual field
        // if (rules.isNumber && isNaN(sizeGroupState[field])) {
        //   errors[field] = `Please enter a valid number for ${field}`;
        // }
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    setSizeGroupState(
      selectedSizeGroup ? res_get?.data?.size_group : bluePrint
    );
    setInputFields(res_get?.data?.size_group?.group_sizes ?? [fields]);
  }, [res_get]);

  const handleFormChange = (index, event) => {
    const { name, value, type, checked } = event.target;

    // If it's a radio button, update the checked state for all radio buttons in the same group
    if (type === "radio") {
      setInputFields(
        inputFields.map(
          (eng, i) =>
            i === index
              ? { ...eng, [name]: checked ? 1 : 0 }
              : { ...eng, [name]: 0 } // Uncheck other radio buttons in the same group
        )
      );
    } else {
      // Handle other input types as usual
      setInputFields(
        inputFields.map((eng, i) =>
          i === index
            ? {
                ...eng,
                [name]:
                  eng?.[name]?.en === undefined
                    ? value
                    : { ...eng?.[name], [selectedLanguage]: value },
              }
            : eng
        )
      );
    }
  };

  const addFields = () => {
    setInputFields([...inputFields, fields]);
  };
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const deleteSizeGroupAttributes = (id, index) => {
    deleteSize(id);
    removeFields(index);
    setOpenSnackbar(true);
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
    setOpenSnackbar(false);
  };
  const handleClickOpen = (index) => {
    setOpen(true);
    setIndex(index);
  };
  return (
    <div>
      <SnackAndBackDrop
        showBackDrop={showBackDrop}
        responseAdd={responseAdd}
        responseUpdate={responseUpdate}
        setOpen={setOpen}
        open={open}
      />

      {/* {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )} */}
      {/* <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      /> */}
      {openSnackbar ? (
        <NoHideSnackbar
          responseAdd={responseDelete?.isSuccess === true ? responseDelete : ""}
        />
      ) : (
        ""
      )}

      {sizeGroupState ? (
        <div style={addUpdateDivStyleGlobal}>
          <DZTextValidation
            error={formErrors?.title}
            label="Title"
            variant="outlined"
            value={sizeGroupState?.title}
            onChange={(e) => {
              setSizeGroupState((x) => ({ ...x, title: e.target.value }));
            }}
            placeholder="Title"
          />
          <DZTextValidation
            error={formErrors?.short_title}
            label="Short Title"
            variant="outlined"
            value={sizeGroupState?.short_title}
            onChange={(e) => {
              setSizeGroupState((x) => ({ ...x, short_title: e.target.value }));
            }}
            placeholder="Short Title"
          />
          {/* sizes add dynamically */}
          <DZLabel title={"Sizes "} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Button
              size="small"
              variant="outlined"
              startIcon={<ControlPointOutlinedIcon />}
              onClick={addFields}
            >
              Add More
            </Button>
          </div>
          <form>
            {inputFields?.map((input, index) => {
              return (
                <Card sx={{ overflow: "visible", mt: 4, border: 1 }}>
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      {index1 === index ? (
                        <DZDeleteDialog
                          title={"Are you sure about deleting this Size?"}
                          description={
                            " You won't be able to recover this size later"
                          }
                          openBox={open}
                          handleClose={() => handleClose()}
                          handleDeleteFunctionCall={() =>
                            deleteSizeGroupAttributes(input?.id, index)
                          }
                        />
                      ) : (
                        ""
                      )}

                      {input?.id ? (
                        <Button
                          variant="outlined"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleClickOpen(index)}
                        >
                          Delete
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          startIcon={<DeleteIcon />}
                          sx={{ color: "red" }}
                          onClick={() => removeFields(index)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                    <div key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={input?.default}
                            name="default"
                            onChange={(event) => handleFormChange(index, event)}
                          />
                        }
                        label="Default"
                      />
                      <Grid container spacing={3.75}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <input
                              type="hidden"
                              name="id"
                              value={input?.id}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            />

                            <TextField
                              fullWidth
                              label="Title"
                              type="text"
                              name="title"
                              variant="outlined"
                              noOfRows={1}
                              value={input.title?.[selectedLanguage]}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <TextField
                              fullWidth
                              label="Short Title"
                              type="text"
                              name="short_title"
                              variant="outlined"
                              noOfRows={1}
                              value={input.short_title?.[selectedLanguage]}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            />
                          </Div>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              );
            })}
          </form>

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedSizeGroup(null));
              }}
            >
              Cancel
            </Button>
            {selectedSizeGroup?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateSizeGroupData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateSizeGroupData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedSizeGroup(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateSizeGroup;
